import React from "react";
import { Button, Container } from "react-bootstrap";
import constant from "../constant/constant";
import Carousel from "react-bootstrap/Carousel";
import { SoundOutlined, AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";

import { TypeAnimation } from "react-type-animation";
import Bannersmenu from "./Bannersmenu";

class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      isMuted: true, // Initially muted
    };
    // Bind methods
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.unmuteOnInteraction = this.unmuteOnInteraction.bind(this);
    this.toggleMute = this.toggleMute.bind(this);


  }



  componentDidMount() {

    // // Add event listeners for user interaction
    // window.addEventListener('click', this.unmuteOnInteraction);
    // window.addEventListener('scroll', this.unmuteOnInteraction);

  }

  componentWillUnmount() {
    // // Clean up event listeners when the component unmounts
    // window.removeEventListener('click', this.unmuteOnInteraction);
    // window.removeEventListener('scroll', this.unmuteOnInteraction);
  }


  unmuteOnInteraction() {
    const video = document.getElementById("bannerVideo");
    if (video) {
      video.muted = false; // Unmute the video after user interaction
    }

    // // Remove event listeners after the interaction
    // window.removeEventListener('click', this.unmuteOnInteraction);
    // window.removeEventListener('scroll', this.unmuteOnInteraction);
  }

  toggleMute() {
    const video = document.getElementById("bannerVideo");
    if (video) {
      // Toggle the muted state
      video.muted = !this.state.isMuted;
      // Update the state to reflect the current mute status
      this.setState({ isMuted: !this.state.isMuted });
    }
  }

  handleAnimationEnd() {
    // Move to the next slide when TypeAnimation completes
    this.setState((prevState) => {
      if (prevState.index === 3) {
        return { index: 0 };
      } else {
        return { index: prevState.index + 1 };
      }
    });
  }

  handleSelect(selectedIndex) {
    console.log(selectedIndex);
    // Update the index state based on the selected index
    if (selectedIndex === 3) {
      this.setState((prevState) => ({ index: prevState.index + 1 }));
    } else {
      this.setState({ index: selectedIndex });
    }
  }

  render() {
    return (
      <div className="bg-blacks">
      <section className="mt-lg-0" id="t-bannersec">
        {/* <Carousel className="cor-item" interval={48000} indicators={false}>
          <Carousel.Item> */}
          <div>

         
            <video
              id="bannerVideo"
              style={{ width: "100%",height:"100vh" }}
              autoPlay
              playsInline
              muted={this.state.isMuted} // Control muting with state
              loop
            >
              <source
                media="(min-width: 768px)"
                src={require("../constant/images/DesktopVideo.mp4")}
                type="video/mp4"
              />
              <source
                media="(max-width: 767px)"
                src={require("../constant/images/MobileVideo.mp4")}
                type="video/mp4"
                className=""
              />
              Your browser does not support the video tag.
            </video>
            <div className="center-image" onClick={this.toggleMute}>
              {this.state.isMuted ? (
                <i class="fas fa-volume-mute text-white"></i>
              ) : (
                <i class="fas fa-volume-up text-white"></i>
              )}
            </div>
            </div>
          {/* </Carousel.Item> */}

          {/* <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/banner1.webp")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mob-banner-1.webp")}
              />
              <img
                src={require("../constant/images/banner1.webp")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-5"
              />
            </picture>

            <div className="carousel-caption carousel-content mt-80">
              <TypeAnimation
                sequence={[
                  `The Hunger Pang Biryani`,
                  2000,
                  `The No Nonsense Biryani`,
                  2000,
                  `The Happy Wala Biryani`,
                  2000,
                  `The Biryani Of Mysore`,
                  2000,
                  `The Biryani of Arcot Nawab’s`,
                  2000,
                  `The Biryani of Bangalore`,
                  2000,
                  // this.handleAnimationEnd
                ]}
                repeat={Infinity}
                wrapper="h3"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <h3 className="theme-color menu-text pos-absl d-md-block d-none">
                Classic Mysore Biryani
              </h3>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/Mysore.webp")}
              />
              <h3 className="theme-color menu-text pos-abr d-md-none d-block fs-3">
                Classic Mysore Biryani
              </h3>
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/MysoreM.webp")}
              />

              <img
                src={require("../constant/images/box-image1.png")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-5"
              />
            </picture>
          </Carousel.Item>

          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/BLR.webp")}
              />
              <h3 className="theme-color menu-text pos-absl d-md-block d-none">
                Epic Bangalore Biryani
              </h3>

              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/BLRM.webp")}
              />
              <h3 className="theme-color menu-text pos-abr d-md-none d-block fs-3">
                Epic Bangalore Biryani
              </h3>
              <img
                src={require("../constant/images/box-image2.png")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-5"
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <h3 className="theme-color menu-text pos-absl d-md-block d-none">
                Chicken 65 Boneless Biryani
              </h3>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/65.webp")}
              />
              <h3 className="theme-color menu-text pos-abr d-md-none d-block fs-3">
                Chicken 65 Boneless Biryani
              </h3>
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/65M.webp")}
              />
              <img
                src={require("../constant/images/box-image3.png")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-5"
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <h3 className="theme-color menu-text pos-absl d-md-block d-none">
                Hyderabadi Biryani
              </h3>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/HYDB.webp")}
              />
              <h3 className="theme-color menu-text pos-abr d-md-none d-block fs-3">
                Hyderabadi Biryani
              </h3>
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/HYDM.webp")}
              />
              <img
                src={require("../constant/images/box-image3.png")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-5"
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item> */}
        {/* </Carousel> */}


       

       
      </section>
      <section>
        <Bannersmenu/>
      </section>
      </div>

    );
  }
}

export default Banners;
