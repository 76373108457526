import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, MarkerF, Autocomplete } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message, Drawer, Button, Input } from "antd";
import { HomeOutlined, LaptopOutlined, EnvironmentOutlined } from "@ant-design/icons";

// Google Maps configuration
const containerStyle = {
  width: '100%',
  height: '300px'
};

const center = {
  lat: 12.9716, // Default location (e.g., Bangalore)
  lng: 77.5946
};

// Icon configuration (custom marker icon)
const customIcon = {
  url: "https://cdn-icons-png.flaticon.com/512/684/684908.png", // Replace with your icon URL or local image path
  scaledSize: { width: 30, height: 30 }, // Adjust the size of the icon
  anchor: { x: 20, y: 40 } // Anchor point of the icon (centered)
};

const CardDrawerMap = ({ openDrawer, onClose, onPass }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState(center);
  const [formattedAddress, setFormattedAddress] = useState(""); // State to store full formatted address
  const autocompleteRef = useRef(null);

  const [doorFlatNo, setDoorFlatNo] = useState(""); // State for Door/Flat No.
  const [landmark, setLandmark] = useState(""); // State for Landmark
  const [addressType, setAddressType] = useState("Home"); // State for address type
  const [zipcode, setZipcode] = useState(""); // State for address type

  // Handle location selection on map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
    console.log("Selected Location:", { lat, lng });
    getAddressFromCoordinates(lat, lng);
  };

  // Handle place selection via Autocomplete
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const { lat, lng } = place.geometry.location;
      setSelectedLocation({
        lat: lat(),
        lng: lng(),
      });
      console.log("Selected Place Location:", { lat: lat(), lng: lng() });
      getAddressFromCoordinates(lat(), lng());
    }
  };

  const getAddressFromCoordinates = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBoMO9HVyopxwZ5XzMiF1Xs7DVy8SU7NqY`
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "OK") {
            const formatted_address = responseJson?.results?.[0]?.formatted_address;
            console.log("Formatted Address:", formatted_address);
  
            // Find and extract the postal code (zipcode)
            let zipcode = "";
            responseJson.results[0].address_components.forEach((component) => {
              if (component.types.includes("postal_code")) {
                zipcode = component.long_name;
              }
            });
  
            console.log("Zip Code:", zipcode);
  
            // Set the formatted address and zipcode in state
            setFormattedAddress(formatted_address);
            setZipcode(zipcode);
  
            resolve({ formatted_address, zipcode });
          } else {
            reject("Address not found");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  

  // Proceed to checkout and pass the address details
  const handleSaveAndProceed = () => {
    const addressDetails = {
      doorFlatNo,
      landmark,
      addressType,
      location: selectedLocation,
      fullAddress: formattedAddress,
      pincode:zipcode,
    };
    console.log("Address Details:", addressDetails);
    onPass(addressDetails); // Pass the full address details back to the parent component
    message.success("Address saved successfully");
    onClose(); // Close the drawer
  };

  // Render Google Map and Autocomplete
  const renderMap = () => {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyBoMO9HVyopxwZ5XzMiF1Xs7DVy8SU7NqY" // Replace with your actual API key
        libraries={["places"]} // Load the Places library for Autocomplete
      >
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceSelect}
        >
          <input
            type="text"
            placeholder="Search for a location"
            style={{
              width: "100%",
              height: "40px",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          />
        </Autocomplete>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={selectedLocation}
          zoom={16}
          onClick={handleMapClick}
        >
          <MarkerF position={selectedLocation} icon={customIcon} />
        </GoogleMap>
      </LoadScript>
    );
  };

  return (
    <>
      <Drawer
        title="Location"
        width={400}
        onClose={onClose}
        open={openDrawer}
        className="position-relative"
      >
        {/* Render Google Map and Autocomplete */}
        <div className="mt-80">{renderMap()}</div>

        {/* Input for Door/Flat No. */}
        <Input
          placeholder="Door / Flat No."
          value={doorFlatNo}
          onChange={(e) => setDoorFlatNo(e.target.value)}
          style={{ marginTop: "20px" }}
        />

        {/* Input for Landmark */}
        <Input
          placeholder="Landmark"
          value={landmark}
          onChange={(e) => setLandmark(e.target.value)}
          style={{ marginTop: "20px" }}
        />

        {/* Address Type Selection */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <Button
            icon={<HomeOutlined />}
            type={addressType === "Home" ? "primary" : "default"}
            onClick={() => setAddressType("Home")}
          >
            Home
          </Button>
          <Button
            icon={<LaptopOutlined />}
            type={addressType === "Work" ? "primary" : "default"}
            onClick={() => setAddressType("Work")}
          >
            Work
          </Button>
          <Button
            icon={<EnvironmentOutlined />}
            type={addressType === "Other" ? "primary" : "default"}
            onClick={() => setAddressType("Other")}
          >
            Other
          </Button>
        </div>

        <Button className="mt-4" onClick={handleSaveAndProceed} style={{ marginTop: "20px" }}>
          Save Address & Proceed
        </Button>
      </Drawer>
    </>
  );
};

export default CardDrawerMap;
