// rootReducer.js

import { combineReducers } from 'redux';
import dataReducer from './reducer';

const rootReducer = combineReducers({
  data: dataReducer,
  productlist: dataReducer,
  storelist: dataReducer,
  userData: dataReducer,
  AddressData: dataReducer,
  loginData: dataReducer,
  getprofile: dataReducer,
  getOrder: dataReducer,
  GetProductId: dataReducer,
  GetAddcardRes: dataReducer,
  GetAddcardUserRes: dataReducer,
  DeleteAddcardUserRes: dataReducer,
  qtyAddcardRes: dataReducer,
  otpVerificationResponse: dataReducer,
  CouponRes: dataReducer,
  loginmodal: dataReducer,
  pincodemodal:dataReducer,
  addaddressres: dataReducer
});

export default rootReducer;
