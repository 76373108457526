import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, message, Button } from "antd";
import { Table } from "antd";
import { fetchStoreData } from "../reducer/thunks";
import AddressModel from "./AddressModel";

const Address = () => {
  const userId = localStorage.getItem("userId");
  const [isAddressModelVisible, setAddressModelVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    loading: isLoading,
    Ordererror: getOrderUserError,
    storelist: getOrderResponse,
  } = useSelector((state) => state.storelist);

  useEffect(() => {
    dispatch(fetchStoreData(userId));
  }, [dispatch, userId]);

  const showAddressModel = () => {
    setAddressModelVisible(true);
  };

  const closeAddressModel = () => {
    setAddressModelVisible(false);
  };

  // Define columns for the Ant Design Table component
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street",
    },
    {
      title: "Type Address",
      dataIndex: "typeAddress",
      key: "typeAddress",
    },
  ];

  return (
    <div className="col-md-9 p-4">
      <div className="col-md-12 bg-white p-3 rounded">
        <div className="mx-4 mt-3">
          <div className="d-flex justify-content-between">
            <p className="p-header">Address</p>
            <Button className="button h-25" onClick={showAddressModel}>
              Add New Address
            </Button>
          </div>
          <Table
            dataSource={getOrderResponse?.Addresslist}
            columns={columns}
            loading={isLoading}
            pagination={false}
            scroll={{ x: '100%' }} // Set horizontal scroll
          />
        </div>
      </div>
      <AddressModel
        onClose={closeAddressModel}
        visible={isAddressModelVisible}
      />
    </div>
  );
};

export default Address;
