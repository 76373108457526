import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { fetchBannerData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';



export default function Terms() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Destructuring with different names to avoid conflict
    const {
        data,
        loading: bannerLoading,
        error: bannerError,
    } = useSelector((state) => state.data);


    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta
                    name="description"
                    content="This is a description of your page."
                />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>
            <Header />

            {/* <section>
        <Carousel className="cor-item">
          <Carousel.Item >
           <img src={require("../constant/images/banner1.jpeg")} className="w-100" />
            <div className="carousel-caption carousel-content mt-80">
            <TypeAnimation
              sequence={[
                `The hunger pang Biryani`,
                2000,
                `The No nonsense Biryani`,
                2000,
                ` The Happy Wala Biryani`,
                2000,
                `The Biryani of Mysore`,

              ]}
              repeat={Infinity}
              wrapper="h3"
            />
          </div>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
             
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
              
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </section> */}
            <section className="mt-5 mt-lg-0">
                <Carousel className="cor-item">
                    <Carousel.Item >
                        {/* <img src={require("../constant/images/banner1.jpeg")} className="w-100" /> */}

                        <picture>
                            <source
                                media="(min-width: 768px)"
                                srcSet={require("../constant/images/banner1.jpeg")}
                            />
                            <source
                                media="(max-width: 767px)"
                                srcSet={require("../constant/images/mob-banner-1.jpg")}
                            />
                            <img
                                src={require("../constant/images/banner1.jpeg")}
                                alt="Flowers"
                                style={{ width: "100%" }}
                            />
                        </picture>

                        <div className="carousel-caption carousel-content mt-80">
                            <TypeAnimation
                                sequence={[
                                    `The Hunger Pang Biryani`,
                                    2000,
                                    `The No Nonsense Biryani`,
                                    2000,
                                    ` The Happy Wala Biryani`,
                                    2000,
                                    `The Biryani Of Mysore`,
                                    2000

                                ]}
                                repeat={Infinity}
                                wrapper="h3"
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        {/* <img src={require("../constant/images/box-image.png")} className="w-100" /> */}
                        <picture>
                            <source
                                media="(min-width: 768px)"
                                srcSet={require("../constant/images/box-image.png")}
                            />
                            <source
                                media="(max-width: 767px)"
                                srcSet={require("../constant/images/mbanner-box.png")}
                            />
                            <img
                                src={require("../constant/images/box-image.png")}
                                alt="Flowers"
                                style={{ width: "100%" }}
                            />
                        </picture>

                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>

            </section>

            <section className="breadcum-bg " style={{ maxHeight: "60px" }}></section>
            <section className="my-md-5 my-3  bg-light"></section>
            <section className="py-lg-5 ">
                <div className="container about-box cont-bg">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-4">
                            <h2 className="theme-color">Terms & Conditions</h2>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-md-12 pb-5 pb-lg-0">
                            <div className="col-md-12 mb-4">
                                <p style={{ textAlign: 'justify', whiteSpace: "pre-wrap" }}>
                                    <strong> Welcome to localheroes.in</strong> <br /> <br />


                                    <ul>This terms and conditions of usage in this platform (website) is toset regulations in your order of homemade food from this site, which is provided by localheroes.com. By purchasing food products from this site, you are indicating your acknowledgment and acceptance of these terms and conditions.</ul>

                                    <ul> User should understand that by ordering products via the Website, User agrees to be bound by these Website Terms.</ul>

                                    <ul> Please understand that if user refuse to accept these Website Terms, user will not be able to order any products from our Website.</ul>

                                    <strong> About Us</strong>
                                    <ul>Local Heros Pvt Ltd Located at 3rd Floor, 9th, A Main 559,
                                        9th A Main Rd, 1st Stage, Indiranagar,
                                        Bengaluru, Karnataka 560008.</ul>

                                    <ul> We provide a platform for every user to availactual home-made food from a large number of home chefs listed on our website.</ul>

                                    <strong>A. Placing an Order</strong>
                                    <ul> Once user have selected the food item from the menu, user will have to submit the order.</ul>

                                    <ul> Please note it is important that user check the information entered is correct and for any errors if any. On receipt of the payment, LOCAL HEROES will begin processing the order and we will send notification by email that payment has been received and the order is being processed.</ul>

                                    <ul> Where any payment user make is not authorized, user will be returned to the previous page on the Website and we shall not be obliged to provide the services.</ul>

                                    <ul>  Please note that once user have made the order and payment has been authorized you will not be able to cancel your order nor will you be entitled to a refund.</ul>

                                    <ul> Please note that from time to time there may be delays with processing payments and transactions, on occasion this can take up to sixty (2 to 45) days to be deducted from your bank account.</ul>

                                    <strong>B. Payment and Listed Price</strong>
                                    <ul> Prices will be as listed on Website. These prices include VAT but may exclude delivery costs & special packaging which will be added to the total amount due, wherever applicable.</ul>

                                    <ul> Payment for all orders must be byPatTM Wallet, credit, debit card or Net Banking as listed.</ul>

                                    <ul> Please note that from time to time there may be delays with processing payments and transactions, on occasion this can take up to sixty (2 to 45) days to be deducted from your bank account or credit card.</ul>

                                    <strong>C. Cancellation</strong>
                                    <ul> There is no guarantee for food delivery at a particular time or time slot. In the event of customer cancelling the order or not accepting the order due to delay in food delivery, no refund will be processed by LOCAL HEROES.</ul>

                                    <ul> Due to unforeseen circumstances i.e. bad weather etc, if we are unable to deliver the food at your address, you will not be charged for the order.</ul>

                                    <strong>D. Refund</strong>
                                    <ul>There is no guarantee for food delivery at a particular time or time slot. In the event of customer cancelling the order or not accepting the order due to delay in food delivery, no refund will be processed by LOCAL HEROES.</ul>

                                    <ul>Due to unforeseen circumstances i.e. bad weather etc, if we are unable to deliver the food at your address, customer will not be refunded.</ul>

                                    <strong>E. Alerts</strong>
                                    <ul>We may alert you by email or phone (through sms/call/email) to inform you about new service offerings of the Company and its subsidiaries/affiliatesor other information which we feel might be useful for you, through theCompany or its subsidiaries/affiliates.</ul>

                                    <strong>F. Log information</strong>
                                    <ul>When you access our Website, our servers automatically record information that your browser sends whenever you visit a website.</ul>

                                    <ul>These server logs may include information such as your web request, internet protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser.</ul>

                                    <strong>G. DNC Update</strong>
                                    <ul>The Company (including but not limited to its subsidiaries/affiliates) may, based on any form of access to the Application (including free download/trials) or Services or Website or registrations through any source whatsoever, contact the User through sms, email and call, to give information about their offerings and products as well as notifications on various important updates and/or to seek permission for demonstration of its products.</ul>

                                    <ul>The User expressly grants such permission to contact him/her through telephone, SMS, e-mail and holds the Company (including but not limited to its subsidiaries/affiliates) indemnified against any liabilities including financial penalties, damages, expenses in case the User's mobile number is registered with Do not Call (DNC) database.</ul>

                                    <ul>By registering yourself, you agree to make your contact details available to Our employees, associates, subsidiaries,affiliates and partners so that you may be contacted for home made food services , offerings and promotions through telephone, SMS, email etc.</ul>

                                    <strong>H. Local Heroes Liability</strong>
                                    <ul>LOCAL HEROES, and any of our group companies and the officers, director, employees, or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, or loss or damages arising from or connected in any way to business interruption, loss of opportunity, loss of anticipated savings, wasted management or office time and whether in tort (including negligence), contract or otherwise, even if foreseeable) in connection with our services, this Website or in connection with the use, inability to use or the results of use of this Website, any websites linked to this Website or the material on these websites.</ul>

                                    <ul>User understand and acknowledge that</ul>

                                    <ul>(i)LOCAL HEROES does not warrant the suitability of the Licensed Application to transact business, or receive products or services from any Chef who uses the business interface of the Licensed Application; and</ul>

                                    <ul>(ii) the Application Provider will not be liable for breach of any understanding and agreement between you and the chef and will not be responsible for any service levels, guarantees, or quality promised by the chef in relation to any products or services procured from the chef. The Application Provider disclaims all liability in relation to any transaction, arrangement or contract entered into between you and the chef through the Licensed Application and will not be responsible for any breach of such contracts or to any claims for losses, costs, damages, expenses or other legal action arising out of such transaction, arrangement or contracts. Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from LOCAL HEROES's negligence, nor LOCAL HEROES's liability for fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under applicable law. Nothing in these Website Terms affects your statutory rights.</ul>

                                    <strong>I. Law and Jurisdiction</strong>
                                    <ul>These Website Terms shall be governed by and construed in accordance with Indian law. Disputes arising in connection with these Website Terms (including non-contractual disputes) shall be subject to the exclusive jurisdiction of the courts of Bangalore (India).</ul>

                                    <strong>J. Situations Not In Our Control</strong>
                                    <ul>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a contract that is caused by events outside our reasonable control (Force Majeure Event).</ul>

                                    <ul> A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:</ul>

                                    <ul>strikes, lock-outs or other industrial action;civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war; fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster; impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;impossibility of the use of public or private telecommunications networks; andthe acts, decrees, legislation, regulations or restrictions of any government</ul>

                                    <ul> Our performance under any contract is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavors to bring the Force Majeure Event to a close or to find a solution by which our obligations under the contract may be performed despite the Force Majeure Event.</ul>
                                    <strong>K. The Agreement</strong>
                                    <ul>These terms and conditions and any document expressly referred to in them constitute the whole agreement between us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the subject matter of any contract.</ul>
                                    <strong>L. Termination</strong>
                                    <ul> We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</ul>

                                    <ul>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</ul>

                                    <ul>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.</ul>

                                    <ul>If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive terminationshall survive termination, including, without limitation, ownership provisions, warrantydisclaimers, indemnity and limitations of liability.  </ul>
                                </p>
                            </div>
                            <div>
                                <strong>PRIVACY POLICY</strong> <br /> <br />

                                <strong> 1. Introduction </strong>
                                <ul> This policy explains how we LocalHeroes, use the personal information which you provide to us when using our service, including but not limited to our website and mobile applications (jointly referred as “Website”).</ul>

                                <ul>Please read this Privacy Policy and understand it before using our services. By visiting and/or ordering services on this Website, you agree and, where required, consent to the collection, use and transfer of your information as set out in this policy.</ul>

                                <strong> 2. What information do we collect about you? </strong>
                                <ul>We collect personal information from you when you order goods or services from us or use our Website.</ul>

                                <ul>We also collect information when you complete any customer survey. Website usage information may also be collected using cookies (as defined below). LocalHeroes will collect information that personally identifies you and/or your location, where required (this may include your name, email address, home address, telephone number, geolocation, etc.), but only when you voluntarily give it to us. </ul>

                                <ul> We collect this information exclusively to carry out the functions offered on the Website and to provide you with offers and information about LocalHeroes and other services we think you may be interested in.</ul>

                                <ul> We might collect this personal information through: online food ordering; entry into competitions; subscribing to our newsletter; creating a user account; sending 'contact us' messages or other correspondence through the Website; or through advertising, research and direct marketing. We do not collect sensitive information about you.</ul>

                                <strong> 3. Cookies</strong>
                                <ul> Some of the information collected will not personally identify you but will instead track your use of the Website so that we can better understand how the Website is used by customers and in turn enhance and improve your experience in ordering food.</ul>

                                <ul> We may obtain this information by use of cookies. Cookies are a small data file transferred to your device that recognizes and identifies your device and allows your device to 'remember' information from the Website for future use.</ul>

                                <ul> Cookies do not contain any information that personally identifies you and we do not use cookies in order to obtain such information - your personal information can only be obtained by LocalHeroes if you actively provide it to us.</ul>

                                <ul> We may collect technical information from your mobile device or your use of our services through a mobile device, for example, location data and certain characteristics of, and performance data about your device, carrier/operating system including device and connection type, IP address, mobile payment methods etc. Your device and/or the web browser should allow you to refuse cookies if you wish by changing the settings.</ul>

                                <ul>To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit localheros.in.</ul>

                                <strong> 4. Storage and security of your personal information</strong>

                                <ul>LocalHeroes will use all reasonable endeavours to maintain the security of your personal information and to protect your personal information from misuse, interference and loss and against unauthorized access, modification or disclosure.</ul>

                                <ul>LocalHeroes will destroy any personal information it holds about you which it no longer requires under the terms of this Privacy Policy. Where you have chosen a password to access certain services of the Website, you are responsible for keeping your password confidential.</ul>

                                <ul>We recommend not sharing your password with anyone. Due to the nature of the internet, LocalHeroes does not provide any guarantee or warranty regarding the security of your personal information during transmission to us or storage by us and you acknowledge that you disclose your personal information to us at your own risk.</ul>

                                <ul>Please contact us immediately if you become aware or have reason to believe there has been any unauthorized use of your personal information in connection with the Website.</ul>

                                <strong>5. How will we use the information we collect from you?</strong>
                                <ul>You consent to LocalHeroes collecting and using your personal information for processing and delivering the order/s placed on the Website and any other service provided through the Website.</ul>

                                <ul>In particular you expressly consent that LocalHeroes may disclose your personal information, including your name, email address, physical address and telephone number to riders delivering your order, either employed by LocalHeroes or by third parties providing delivery services to LocalHeroes.</ul>

                                <ul>You consent to LocalHeroes using your personal information for advertising and direct marketing purposes in order to inform you about the Website and the goods and services it provides, or other matters that it believes will be of interest to you.</ul>

                                <ul>LocalHeroes may disclose your personal information, and you consent to the disclosure of your personal information, to: other entities within the LocalHeroes group of companies; and third parties engaged by LocalHeroes or other members of the LocalHeroes group to perform functions or provide products and services on our behalf such as processing payments, mail outs, debt collection, research, statistical information, marketing and direct or indirect advertising.</ul>

                                <ul>If you would like to unsubscribe from receiving direct marketing communications from LocalHeroes or do not want us to share your personal information with other parties, please e-mail us at  info@localheros.in or follow the steps to unsubscribe which are presented in every communication you receive from us, requesting your personal information be removed from our mailing list.</ul>

                                <ul>We will use reasonable endeavors to comply with your request within a reasonable period of receipt of your request. Please note that the withdrawal of any authorizations for processing of your personal information by third parties may result in us not being able to provide you with any services.</ul>

                                <ul>LocalHeroes reserves the right to disclose your personal information if it is required or authorized to do so by law, or, if it is reasonably necessary in its opinion to protect the rights or property of LocalHeroes or any third party, or to avoid injury to any person. If the LocalHeroes business is sold or merges with another entity then some or all of your personal information may be passed to a third party.</ul>

                                <strong>6. Access to your information</strong>
                                <ul>LocalHeroes will use all reasonable endeavors to keep personal information it holds accurate, complete, up-to-date, relevant and not misleading. Please contact us if you would like to access the personal information LocalHeroes holds about you.</ul>

                                <ul>We will use reasonable endeavors to provide a complete list of your personal information within a reasonable period of receipt of your request. Please email us if you would like to receive a copy of this information –  info@localheros.in.</ul>

                                <ul>LocalHeroes reserves the right to charge a nominal fee for the processing of this request in accordance with local legislation. You may contact us to correct any of your personal information that is inaccurate, incomplete or out-of-date, or to request that your personal information be deleted. We will use reasonable endeavors to correct or delete your personal information as requested within a reasonable period of receipt of your request.</ul>

                                <ul>Deletion of your account with LocalHeroes will not automatically delete the personal information held about you. If you would like LocalHeroes to delete all personal information together with the deletion of your account, please follow the steps mentioned-above.</ul>

                                <ul>Please note that the deletion of your personal information from our database will result in us not being able to provide you with any services.</ul>

                                <ul>If applicable, any legal requirement on us to maintain certain records of your personal information shall prevail over any of your requests.</ul>

                                <ul>We may require you to prove your identity before providing you with copies of your personal information.</ul>

                                <strong>7. Changes to our Privacy Policy</strong>
                                <ul>LocalHeroes reserves the right to alter all or any part of this Privacy Policy. Any changes thereto will be notified via the Website and, where appropriate, through e-mail notification.</ul>

                                <strong>8. Other Websites</strong>
                                <ul>Our Website may have links to other websites. This privacy policy only applies to the Website. You should therefore read the privacy policies of the other websites when you are using those sites.</ul>

                                <strong>9. Contact</strong>
                                <ul>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to:  info@localheros.in</ul>

                            </div>
                            <div>
                                <strong>REFUND AND CANCELLATION POLICY</strong> <br />
                                <br />
                                <p>Local Heroes Pvt. Ltd (“Local Heroes”) reserves the right to refuse or cancel any order. Local Heroes at its sole discretion may cancel any order(s):</p>
                                <ul>
                                    <li>If it suspects a fraudulent transaction(s), or</li>
                                    <li>If it suspects a customer has undertaken a transaction which is not in accordance with its Terms and Conditions, or</li>
                                    <li>For any reason outside the control of Local Heroes.</li>
                                    <li>In case the buyer cancels the order before it is accepted by the kitchen, the amount will be refunded.</li>
                                    <li>In case the buyer cancels the order after the order is accepted by the kitchen, no amount will be refunded.</li>

                                </ul>

                                <p>Refund/cancellation policies applicable in the following conditions:</p>
                                <ol>
                                    <li>In case the buyer cancels the order online a day before the service is scheduled, the amount will be refunded.</li>
                                    <li>In case of failed transactions or double realization of account for the same order, the total deducted amount will be refunded.</li>
                                    <li>In case of cancelled order/failed transactions, the bank/card transaction charges of the buyer, if any, are likely to be forfeited.</li>
                                    <li>Local Heroes offers no guarantees whatsoever for the accuracy or timeliness of the refunds in the buyer’s card/account.</li>
                                    <li>In case of part cancellations, the amount refunded will correspond to the part cancellation.</li>
                                </ol>

                                <p>Document Review Date: 01 March 2024<br />
                                    Version 2 of the Refund and Cancellation Policy</p>
                            </div>
                            <div>
                                <strong>SHIPPING AND DELIVERY POLICY</strong>
                                <p>Local Heroes Pvt. Ltd (“Local Heroes”) provides shipping and delivery services for food orders. Please review the following terms and conditions:</p>
                                <ul>
                                    <li><strong>Order Processing:</strong> Once you place a food order through our website, Local Heroes will begin processing the order. Please ensure that all information entered is accurate.</li>
                                    <li><strong>Delivery Time:</strong> Local Heroes strives to deliver food orders promptly. However, delivery times may vary depending on factors such as order volume, distance, and traffic conditions.</li>
                                    <li><strong>Delivery Area:</strong> We currently offer food delivery services within our specified delivery zones. Please check our website for information on delivery areas.</li>
                                    <li><strong>Delivery Charges:</strong> Shipping and delivery charges may apply to food orders, depending on the delivery location and order size. These charges will be clearly communicated during the checkout process.</li>
                                    <li><strong>Food Quality:</strong> Local Heroes ensures that all food items are prepared with the utmost care and hygiene standards. We strive to deliver fresh and delicious meals to our customers.</li>
                                    <li><strong>Special Instructions:</strong> If you have any special delivery instructions or dietary requirements, please provide them during the checkout process. We will do our best to accommodate your requests.</li>
                                </ul>

                                <p>By placing a food order with Local Heroes, you agree to abide by the terms and conditions outlined in this Shipping and Delivery Policy.</p>

                                <strong>Contact Us</strong>
                                <ul>If you have any questions about these Terms, please contact us.  info@localheros.in</ul>
                            </div>



                        </div>

                    </div>

                </div>
            </section>



            <Footer />
        </>
    );
}
