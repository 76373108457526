import React from 'react';
import { Button, Result } from 'antd';

const Offline = () => (
  <Result
    status="403"
    title="403"
    style={{
      marginTop: '100px',
    }}
    subTitle="Sorry, Please check your internet connection."
    extra={
      <Button type="primary" onClick={() => window.location.reload()}>
        Reload
      </Button>
    }
  />
);

export default Offline;
