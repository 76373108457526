import React, { useState, useEffect } from "react";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAddCardProductById,
  DeleteAddCardProductById,
  QtyOrderProductById,
} from "../reducer/thunks";
import constant from "../constant/constant";
import { useNavigate } from "react-router-dom";
import { message, Modal, Button, Result } from "antd";
import Lottie from "react-lottie";

import * as animationData from "./../constant/images/Animation.json";
const deliveryamount = 0;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [close, setOnclose] = useState(false);

  const description = "This is a description.";

  let userId = localStorage.getItem("userId");

  const {
    GetAddcardUserRes,
    addloading: addloadingLoading,
    error: productListError,
  } = useSelector((state) => state.GetAddcardUserRes);
  const {
    qtyAddcardRes,
    qtycardIdloading: qtyloader,
    error: qtycardIdListError,
  } = useSelector((state) => state.qtyAddcardRes);
  const {
    DeleteAddcardUserRes,
    Deleteloading: DeleteloadingLoading,
    error: DeleteloadingError,
  } = useSelector((state) => state.DeleteAddcardUserRes);

  useEffect(() => {
    if ((userId !== undefined && userId !== null) || qtyAddcardRes) {
      dispatch(GetAddCardProductById(userId));
    }
  }, [userId, qtyAddcardRes, DeleteAddcardUserRes]); // Add qtyAddcardRes and DeleteAddcardUserRes as dependencies

  useEffect(() => {
    console.log(DeleteAddcardUserRes);
    if (DeleteAddcardUserRes) {
      // Trigger fetching the updated data after successful deletion
      dispatch(GetAddCardProductById(userId));
      message.success(`Removed from Cart`, 5);
    }
  }, [DeleteAddcardUserRes]);

  const handleUpdateQuantity = (prod_id, qtynumber) => {
    console.log(prod_id, qtynumber, "qtynumber");
    let prod_qty = {
      quantity: String(qtynumber),
      savelater: false,
    };
    dispatch(QtyOrderProductById(prod_qty, prod_id));
  };

  const getTotalPrice = () => {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  };
  const handleRemoveItem = (productId) => {
    dispatch(DeleteAddCardProductById(productId));
  };
  const handleCheckout = () => {
    // setOnclose(true);

    if (GetAddcardUserRes.AddCarts.length > 0) {
      if (
        GetAddcardUserRes.AddCarts.length === 2 ||
        GetAddcardUserRes.quantity === 2 ||
        getTotal() > 150
      ) {
        navigate(`/checkout`);
        setOnclose(true);
      } else {
        message.info(`Min order quantity two`, 5);
      }
    } else {
      message.info(`Please select the Biryani`, 5);
    }
  };

  // Define the getSubtotal function
  function getSubtotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  }

  // Define the getTotal function
  function getTotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  }

  // Dummy content for cart list
  const renderCartList = () => {
    return (
      <div className="cart-list col-md-8 h-25">
        <table className="mt-5 col-md-12">
          <thead>
            <tr className="border-bottom table-head">
              <th>Products</th>
              <th className="product-amount text-center">Price</th>
              <th className="product-amount text-center">Quantity</th>
              <th className="product-amount text-center">Price</th>
              <th className="product-amount text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {GetAddcardUserRes?.AddCarts &&
              GetAddcardUserRes.AddCarts.map((item) => (
                <tr className="table-th" key={item.id}>
                  <td>
                    <div className="product-item mt-4">
                      <div className="product-image w-75">
                        <img
                          src={`${item?.product?.images[0]}`}
                          alt={`Product Image ${item.product?.name}`}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                      <div className="product-details ">
                        <span className="product-name">
                          {item.product?.name}
                        </span>{" "}
                        <br />
                        {item.Options_id !== "" && (
                          <span className="product-name">
                            {item.Options_id?.name}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="product-amount text-center">
                    ₹{item?.product?.amount}
                  </td>
                  <td className="product-amount text-center">
                    <div className="quantity-col d-flex just text-center">
                      <button
                        className="quantity-btn"
                        onClick={() =>
                          handleUpdateQuantity(item._id, item.quantity - 1)
                        }
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span className="quantity">{item.quantity}</span>
                      <button
                        className="quantity-btn"
                        onClick={() =>
                          handleUpdateQuantity(item._id, item.quantity + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="product-amount text-center">
                    ₹{item?.quantity * item?.product?.amount}
                  </td>
                  <td>
                    <button
                      className="delete-button btn button"
                      onClick={() => handleRemoveItem(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* <div className="total-price">Total: ₹{getTotalPrice()}</div> */}
      </div>
    );
  };

  const shippingamount = () => {
    const calculatedAmount = parseFloat(getTotal()).toFixed(0);

    if (450 > +calculatedAmount) {
      return (
        `${calculatedAmount} +(DC) ${deliveryamount} =` +
        " " +
        Number(+calculatedAmount + deliveryamount)
      );
    } else {
      return "Delivery Fee";
    }
  };

  const renderCardtotals = () => {
    return (
      <div className=" col-md-4 price-card">
        <div className="cart-list cart-totals mx-2">
          <h6 className="mt-4 mb-3">Price Details</h6>
          <div className="cart-totals-item border-top mt-3 pt-3">
            <span>Subtotal :</span>
            <span>₹{(getSubtotal() / 1.05).toFixed(2)}</span>
          </div>

          <div className="cart-totals-item">
            <span>SGST :</span>
            <span>₹{(((getSubtotal()/1.05) * 2.5) / 100).toFixed(2)}</span>
          </div>
          <div className="cart-totals-item">
            <span>CGST :</span>
            <span>₹{(((getSubtotal()/1.05)* 2.5) / 100).toFixed(2)}</span>
          </div>
          <div className="cart-totals-item">
            <span>Shipping :</span>
            <span>{shippingamount()}</span>
          </div>
          <div className="cart-totals-item total border-top mt-4 pt-3">
            <span>Total :</span>
            <span>
              ₹
              {shippingamount() === "Delivery Fee"
                ? getTotal()
                : getTotal() + deliveryamount}
            </span>
          </div>
          {/* Proceed to Checkout button */}
          <div className="cart-totals-item-btn mt-4 mb-4">
            <button className="btn button " onClick={handleCheckout}>
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />

      <section className="py-5 mt-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="section-heading">
                <h3 className="introducing theme-bg-text font-hp mt-5">Cart</h3>
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-md-12">
              {/* <Steps
                current={1}
                items={[
                  {
                    title: "Product",
                    description,
                  },
                  {
                    title: "Order Summary",
                    description,
                    subTitle: "Left 00:00:08",
                  },
                  {
                    title: "payment",
                    description,
                  },
                ]}
              /> */}
              <div className="col-md-12 row mt-5">
                {renderCartList()}
                {renderCardtotals()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* <Modal
        visible={close}
        onCancel={() => {
          setOnclose(false);
        }}
        height="300px"
        width="770px"
        style={{ marginTop: "3%" }}
        footer={null}
      >
      
      <h4 className="text-center font-weight-bold head-control mt-5">Our delivery service is launching soon! 
          Stay tuned for updates and get ready to enjoy the convenience of having your favorite Biryani delivered right to your doorstep!!</h4>

        <Result
          title=""
          icon={ <Lottie options={defaultOptions}

          height={250}
          width={250}
          />}
        
         
        />
      </Modal> */}
    </>
  );
};

export default Cart;
