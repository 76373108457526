import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../componets/Header';
import Footer from '../componets/Footer';
import { Divider, Typography } from 'antd';
const { Title, Paragraph, Text, Link } = Typography;


export default function Blogs() {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    const { data } = useSelector((state) => state.data);

    useEffect(() => {
        // Find the blog with the matching ID
        if (data && data.Faqs) {
            const foundBlog = data.Faqs.find((item) => item._id === id);
            setBlog(foundBlog);
            console.log(foundBlog);

        }
    }, [data, id]);

    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta name="description" content="This is a description of your page." />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>
            <Header />
            {blog && (
                <section className='breadcum-bg'>
                    <picture>
                        <source
                            media="(min-width: 768px)"
                            srcSet={blog?.image_mob_Url}
                        />
                        <source
                            media="(max-width: 767px)"
                            srcSet={blog?.imageUrl}
                        />
                        <img
                            src={blog?.imageUrl}
                            alt="Flowers"
                            style={{ width: "100%", maxHeight: "100%" }}
                        />
                    </picture>
                </section>
            )}


            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        {blog && (
                            <div className="col-md-10 mb-4">
                                <h2 className='blog-title text-center'>{blog.name}</h2>
                                <p style={{ textAlign: 'justify',whiteSpace:"pre-wrap" }}>{blog.description}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
