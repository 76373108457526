import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Form, Input, Button, message } from "antd";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Carousel from "react-bootstrap/Carousel";
import Banners from "../componets/Banners";

export default function Bulk() {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      // Handle form submission here
      console.log("Received values:", values);

      const response = await fetch(
        "https://us-central1-email-js-1a09b.cloudfunctions.net/emailjs/local-heros-submit-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        message.success("Form submitted successfully");
        form.resetFields();
      } else {
        console.error("Error sending email:", response.statusText);
        message.error("Error submitting form");
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      message.error("An unexpected error occurred");
    }
  };

  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta name="description" content="This is a description of your page." />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />
      <Banners/>
      <section className="py-5 bg-white">
        <div className="container about-box">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Bulk Order</h2>
            </div>
          </div>
          <div className="row py-5 justify-content-center">
            <div className="col-md-10 bg-white">
              <div className=" px-md-5 px-3 ">
                <Form
                  form={form}
                  onFinish={onFinish}
                  style={{ paddingBottom: "15px" }}
                >
                  <div className="row mb-2">
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="name"
                        rules={[
                          { required: true, message: "Please enter your name" },
                        ]}
                      >
                        <Input
                          placeholder="Name"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="Email"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="pinCode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your pin code",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Pin Code"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number",
                          },
                          {
                            pattern: /^[0-9]{10}$/,
                            message: "Please enter a valid phone number",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Phone"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="productName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the product name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Product Name"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the quantity",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Quantity"
                          className="form-control text-input"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn button h-25 d-flex mx-auto mt-3"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
