import React, { useState, useEffect, useCallback } from "react";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAddCardProductById,
  AddOrderProductById,
  fetchStoreData,
  ApplyCouponFetch,
  ProfileUserData,
  AddAddressFetch,
} from "../reducer/thunks";
import constant from "../constant/constant";
import {
  Steps,
  Input,
  Form,
  message,
  Button,
  Select,
  Modal,
  Result,
  Typography,
} from "antd";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import CardDrawerMap from "./CardDrawerMap";
const { Paragraph, Text } = Typography;
const deliveryamount = 0;

const { Option } = Select;

const Payment = () => {
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const [form] = Form.useForm();
  const description = "This is a description.";
  const navigate = useNavigate();

  let userId = localStorage.getItem("userId");
  // Dummy cart items (replace with actual data)
  const [paynow, setpaynow] = useState(false);
  const [couponNumber, SetCoupon] = useState("");
  const [couponValue, SetCouponValue] = useState(0);
  const [couponType, SetCouponType] = useState(null);
  const [orderType, SetOrderType] = useState(false);
  const [orderfail, SetOrderfail] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [OrderId , setOrderId] = useState("");
  const [drawer, setDrawer] = useState(false);

  const API_KEY = "AIzaSyBoMO9HVyopxwZ5XzMiF1Xs7DVy8SU7NqY";


  const createOrder = async (params) => {
    // Implement your logic to create an order on the backend
    // and return the order details including an 'id'
    // Example:
    const response = await fetch(`${constant.baseUrl}api/order/orders`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const handlePaymentrazorpay = async () => {
    let params = {
      // amount: Math.round(Math.round(calculateTotalAmount()) * 100),
      amount: Math.round(1 * 100),

      currency: "INR",
      receipt: "Receipt no. 1",
      notes: {
        notes_key_1: "Tea, Earl Grey, Hot",
        notes_key_2: "Tea, Earl Grey… decaf.",
      },
    };
    const order = await createOrder(params); //  Create order on your backend

    const options = {
      key: "rzp_live_ZTtFcdDX7OeqJJ", // Enter the Key ID generated from the Dashboard
      amount: Math.round(Math.round(calculateTotalAmount()) * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      //  amount: Math.round(1 * 100),
      currency: "INR",
      name: "LOCAL HEROS",
      description: "Test Transaction",
      image:
        "https://storage.googleapis.com/email-js-1a09b.appspot.com/localHeros/logo.65e819dc790bf567b08e.webp",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: async function (response) {
        console.log(response);
        const quantities = GetAddcardUserRes.AddCarts.map((item) => ({
          productId: item.productId, // Product ID
          quantity: item.quantity, // Quantity
          Options_product_Id: item.Options_item,
        }));

        console.log(form.getFieldValue().addressId);

        let bodyresponce = {
          userId: localStorage.getItem("userId"), // Replace with a valid user ID
          addressId: AddAddressAPIRes?.address?._id
            ? AddAddressAPIRes?.address?._id
            : form.getFieldValue()?.addressId, // Replace with a valid address ID
          productIds: GetAddcardUserRes.AddCarts.map((item) => item.productId), // Replace with valid product IDs in an array
          totalAmount: calculateTotalAmount(), // Replace with the total amount for the order
          delivery: "Card",
          paymentStatus: "Confirmed",
          razorpay_payment_id: response.razorpay_payment_id,
          exta_add_item: "",
          exta_message: "",
          applycoupon: couponNumber,
          quantity: quantities, // Use the array of objects with product ID and quantity
        };

        await dispatch(AddOrderProductById(bodyresponce));
        SetOrderType(true);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: getUserResponse.User.firstname,
        email: getUserResponse.User.email,
        contact: getUserResponse.User.mobilenumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#f56a00",
      },
    };
    try {
      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", async function (response) {
        SetOrderfail(true);
      });
  
      rzp1.open();
    } catch (error) {
      
    }

   
  };

  const {
    GetAddcardUserRes,
   
  } = useSelector((state) => state.GetAddcardUserRes);
  const {
    storelist: getOrderResponse,

  } = useSelector((state) => state.storelist);
  const {
    CouponRes: CouponResponse,
  } = useSelector((state) => state.CouponRes);

  const {
    getprofile: getUserResponse,
  } = useSelector((state) => state.getprofile);

  const {
    addaddressres: AddAddressAPIRes,
  } = useSelector((state) => state.addaddressres);

  const {
    GetAddcardRes,
  } = useSelector((state) => state.GetAddcardRes);

  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    phonenumber: "",
    country: "",
    streetAddress: "",
    townCity: "",
    state: "",
    pinCode: "",
    email: "",
    typeaddress:"",
    landmark:"",
    FlatNo:""
  });

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress({
      ...shippingAddress,
      [field]: value,
    });
  };

  const validateRules = {
    required: { required: true, message: "This field is required" },
    email: {
      type: "email",
      message: "Please enter a valid email",
    },
    // Number validation: restricts to a valid number with custom min and max constraints
    number: {
      pattern: /^[0-9]+$/,
      message: "Please enter a valid number",
    },
    zip: { pattern: /^[0-9]{6}$/, message: "Please enter a valid ZIP Code" },
    // Phone number validation: ensures a 10-digit number
    phone: {
      pattern: /^[0-9]{10}$/,
      message: "Please enter a valid 10-digit phone number",
    },
     // Address validation: ensure that the address is between 10 and 255 characters long
  address: {
    min: 10,  // Minimum length for a valid address
    max: 255, // Maximum length for a valid address
    message: "Address should be between 10 and 255 characters",
    validator: (_, value) => {
      // Custom validation logic can be added here if needed
      if (!value || value.length < 10) {
        return Promise.reject(new Error("Address must be at least 10 characters long"));
      } 
      if (value.length > 255) {
        return Promise.reject(new Error("Address cannot exceed 255 characters"));
      }
      return Promise.resolve(); // If valid, return resolved promise
    }
  },

  };

  const onFinish = async (values) => {
    message.loading("Payment is Going On");

    console.log(shippingAddress);
    // Handle form submission here
    // handlePayment();
    const address = {
      city: "Bangalore",
      street: shippingAddress.address ? shippingAddress.address : form.getFieldValue().address,
      pinCode: shippingAddress.pinCode ? shippingAddress.pinCode :  form.getFieldValue().pinCode,
      typeAddress: form.getFieldValue().typeaddress,
      companyName: shippingAddress.lastName,
      fullName: shippingAddress.firstName,
      phone: shippingAddress.phonenumber,
      state: "Karnataka",
      userId: localStorage.getItem("userId"),
      lat: String(latitude), // Convert latitude to string
      lng: String(longitude), // Convert longitude to string
    };
    console.log(address, "address");

    // Dispatch the AddAddressFetch action
    if (!form.getFieldValue().addressId) {
      await dispatch(AddAddressFetch(address));
      await handlePaymentrazorpay();

    }else{
      await handlePaymentrazorpay();

    }

  };

 



  useEffect(() => {
    if (userId !== undefined || userId !== null) {
      dispatch(GetAddCardProductById(userId));
      dispatch(fetchStoreData(userId));
      dispatch(ProfileUserData(userId));
      // currentloacation()
    }
  }, []);
  useEffect(() => {
    if (CouponResponse?.success) {
      message.success("Coupon applied successfully");

      const { bodysend } = CouponResponse;

      if (bodysend) {
        const { coupon_type, discount } = bodysend;

        SetCouponValue(discount);
        SetCouponType(coupon_type);
      } else {
        // Handle case where bodysend is undefined or null
        console.error("Invalid coupon response: bodysend is missing");
      }
    } else if (typeof CouponResponse === "string") {
      message.error(CouponResponse);
    } else {
      // Handle other types of CouponResponse that are not valid
      console.error("Invalid CouponResponse:", CouponResponse);
    }
  }, [CouponResponse]);

  useEffect(() => {

    if (AddAddressAPIRes?.success) {
      form.setFieldsValue({
        addressId: AddAddressAPIRes?.address?._id,
        firstName: AddAddressAPIRes?.address?.fullName,
        lastName: AddAddressAPIRes?.address?.fullName,
        phonenumber: AddAddressAPIRes?.address?.phone,
        address: AddAddressAPIRes?.address?.typeAddress,
        email: AddAddressAPIRes?.address?.email,
        pinCode: AddAddressAPIRes?.address?.pinCode,
        // Add other fields as needed
      });
    }
  }, [AddAddressAPIRes]);
  // useEffect(() => {
  //   if (GetAddcardRes?.success) {
  //      setOrderId(GetAddcardRes.order._id)
  //   }
   
  // }, [AddAddressAPIRes]);

  useEffect(() => {
    if (userId !== undefined || userId !== null) {
      dispatch(GetAddCardProductById(userId));
      dispatch(fetchStoreData(userId));
    }
  }, []);

  const handlePayNow = () => {
    form.submit((values) => {
      // You can access the form values here and perform any additional actions
      console.log("Form values submitted:", values);

      // You can perform additional actions after form submission if needed
    });
  };

  const currentloacation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getAddressFromCoordinates = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          latitude +
          "," +
          longitude +
          "&key=" +
          API_KEY
      )
        .then((response) => response.json())
        .then((responseJson) => {
          let city,
            country,
            zipcode,
            streetNumber,
            route,
            premise,
            sublocality,
            regionName;
          responseJson?.results?.[0]?.address_components.map(
            (address, index) => {
              address.types.map((types, index) => {
                if (types === "locality") {
                  city = address.long_name;
                } else if (types === "country") {
                  country = address.long_name;
                } else if (types === "postal_code") {
                  zipcode = address.long_name;
                } else if (types === "street_number") {
                  streetNumber = address.long_name;
                } else if (types === "route") {
                  route = address.long_name;
                } else if (types === "premise") {
                  premise = address.long_name;
                } else if (types === "sublocality") {
                  sublocality = address.long_name;
                } else if (types === "administrative_area_level_1") {
                  regionName = address.long_name;
                }
              });
            }
          );
          if (responseJson.status === "OK") {
            console.log(responseJson?.results, "responseJson?.results");
            form.setFieldsValue({
              address: responseJson?.results?.[0]?.formatted_address,
              pinCode: zipcode,
              // Add other fields as needed
            });
            handleShippingAddressChange(
              "address",
              responseJson?.results?.[0]?.formatted_address
            );

            resolve(responseJson?.results?.[0]?.formatted_address);
          } else {
            reject("not found");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const handlePaywithpay = () => {
    const paymentUrl = `upi://pay?pa=MAB.037346029690018@axisbank&pn=Local Heroes Biriyaari&tn=Local Heroes Biriyaari&am=${calculateTotalAmount()}`;
    window.location.href = paymentUrl; // Redirect to payment URL
  };
  // Define the getSubtotal function
  function getSubtitle() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.map((item) => (
        <div className="cart-totals-item d-flex justify-content-start">
          <span>
            <img
              src={`${item?.product?.images[0]}`}
              alt={`Product Image ${item.product.name}`}
              style={{ width: "80px", height: "80px" }}
            />
          </span>
          <span className="p-2 text-start">
            {item?.product?.name} <br />
            {item?.quantity} x ₹{item?.product?.amount}
          </span>
        </div>
      ))
    );
  }
  const handleStateSelect = (value) => {
    // Handle the selected state, e.g., update the state in the component
    console.log("Selected State:", value);

    // Use filter to find the selected address
    const filterAddress = getOrderResponse.Addresslist.find(
      (state) => state._id === value
    );

    // Log the filtered address
    console.log("Filtered Address:", filterAddress);

    // Use setFieldsValue to update the form values
    form.setFieldsValue({
      addressId: filterAddress._id,
      firstName: filterAddress.fullName,
      lastName: filterAddress.fullName,
      phonenumber: filterAddress.phone,
      address: filterAddress.typeAddress,
      email: filterAddress.email,
      pinCode: filterAddress.pinCode,
      // Add other fields as needed
    });
  };

  // Define the getTotal function
  function getTotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product.amount * item.quantity,
        0
      )
    );
  }

  const handleCoupon = () => {
    let bodypass = {
      couponCode: couponNumber,
      userId: userId,
    };
    dispatch(ApplyCouponFetch(bodypass));
  };
  const calculateDiscount = () => {
    let discount = 0;
    if (couponType === "amount") {
      discount = Number(couponValue);
    } else if (couponType === "percentage") {
      discount = (Number(couponValue) / 100) * getTotal();
    } else if (couponType === "bogo") {
      // Example BOGO calculation (adjust as per your requirements)
      discount = getTotal() / 2; // Assuming buy one get one free
    }
    return discount.toFixed(2);
  };

  const renderDiscount = () => {
    const discountAmount = calculateDiscount();
    return <span>{`-${discountAmount}`}</span>;
  };

  const calculateTotalAmount = () => {
    let discount = 0;
    if (couponType) {
      if (couponType === "amount") {
        discount = Number(couponValue);
      } else if (couponType === "percentage") {
        discount = (Number(couponValue) / 100) * getTotal();
      } else if (couponType === "bogo") {
        // Example BOGO calculation (adjust as per your requirements)
        discount = getTotal() / 2; // Assuming buy one get one free
      }
    }

    // Calculate the total amount after discount
    const totalAmount =
      (450 > +getTotal() ? getTotal() + deliveryamount : getTotal()) - discount;
    return totalAmount.toFixed(2);
  };

  const shippingamount = () => {
    const calculatedAmount = parseFloat(getTotal()).toFixed(0);

    if (450 > +calculatedAmount) {
      return (
        `${calculatedAmount} +(DC) ${deliveryamount} =` +
        " " +
        Number(+calculatedAmount + deliveryamount)
      );
    } else {
      return "Delivery Fee";
    }
  };

  const renderCardtotals = () => {
    return (
      <div className="col-md-4 h-25 mt-3 price-card">
        <div className="cart-totals p-4">
          {getSubtitle()}
          <div className="cart-totals-item border-top mt-3 pt-3 align-self-center">
            <span className="m-2">Coupon</span>
            <Form.Item
              name="firstName"
              className="col-md-6"
              labelCol={{ span: 24 }}
              // rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.firstName}
                className="form-control text-input-address"
                onChange={(e) => SetCoupon(e.target.value)}
              />
            </Form.Item>
            <button
              className="btn  text-white bg-theme-color text-center h-25"
              onClick={handleCoupon}
            >
              Apply
            </button>
          </div>
          <div className="cart-totals-item border-top mt-3 pt-3">
            <span>Subtotal :</span>
            <span>₹{(getTotal() / 1.05).toFixed(2)}</span>
          </div>

          <div className="cart-totals-item">
            <span>SGST :</span>
            <span>₹{(((getTotal() / 1.05) * 2.5) / 100).toFixed(2)}</span>
          </div>
          <div className="cart-totals-item">
            <span>CGST :</span>
            <span>₹{(((getTotal() / 1.05) * 2.5) / 100).toFixed(2)}</span>
          </div>
          <div className="cart-totals-item">
            <span>Shipping :</span>
            <span>{shippingamount()}</span>
          </div>
          <div className="cart-totals-item">
            <span>Discount</span>
            {renderDiscount()}
          </div>

          <div className="cart-totals-item total border-top mt-4 pt-3">
            <span>Total</span>
            <div className="">
              <div>
                {couponType && (
                  <del className="stickout-price mx-3 red-code">
                    ₹{getTotal().toFixed(2)}
                  </del>
                )}

                <span>₹{calculateTotalAmount()}</span>
              </div>
            </div>
          </div>
          <div className="cart-totals-item-btn mt-4 mb-4">
            <button
              type="button"
              className="btn button "
              onClick={handlePayNow}
            >
              Proceed to Payment
            </button>
          </div>
        </div>
        {/* {renderPaymentForm()} */}
      </div>
    );
  };

  const renderShippingAddressForm = () => {
    return (
      <div className="shipping-address col-md-8 mt-3 h-25 price-card">
        <h3 className="shipping-header">Billing Information</h3>

        <Form form={form} name="shippingAddressForm" onFinish={onFinish}>
          <div className="col-md-12 row">
            <div className="col-md-12">
              {getOrderResponse && (
                <Form.Item
                  name="addressId"
                  label="Select an Address"
                  labelCol={{ span: 24 }}
                  className="col-md-6"
                  // rules={[validateRules.required]}
                >
                  <Select
                    placeholder="Select an Address"
                    onChange={(selectedState) =>
                      handleStateSelect(selectedState)
                    }
                  >
                    {getOrderResponse &&
                      getOrderResponse?.Addresslist &&
                      getOrderResponse?.Addresslist.map((state) => (
                        <Option key={state._id} value={state._id}>
                          {state.typeAddress}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </div>

            <Form.Item
              name="firstName"
              label="Name"
              className="col-md-6"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.firstName}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("firstName", e.target.value)
                }
              />
            </Form.Item>

            {/* <Form.Item
              name="lastName"
              label="Last Name"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.lastName}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("lastName", e.target.value)
                }
              />
            </Form.Item> */}

            <Form.Item
              name="phonenumber"
              label="Phone Number"
              className="col-md-6"
              labelCol={{ span: 24 }}
              rules={[
                validateRules.required,validateRules.phone]}
            >
              <Input
                value={shippingAddress.phonenumber}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("phonenumber", e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-12 row" onClick={()=>setDrawer(!drawer)}>
            <Form.Item
              name="address"
              label="Address No / Steet Name"
              className="col-md-11"
              labelCol={{ span: 24 }}
              
              rules={[validateRules.required,validateRules.address]}
            >
              <Input
                value={shippingAddress.address}
                className="form-control text-input-address"
                
                onChange={(e) =>
                  handleShippingAddressChange("address", e.target.value)
                }
              />
            </Form.Item>
            <div
              className="d-flex col-md-1 mt-4"
              style={{ cursor: "pointer" }}
              onClick={()=>setDrawer(!drawer)}
            >
              <img
                src={require("../assets/images/location-home.png")}
                className="logo-wid-location mt-3"
                alt="Logo"
              />
              <div>
                <p className="text-white location-text">
                  {/* {pincodeId ? pincodeId : 'Location'} */}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 row">
            <Form.Item
              name="landmark"
              label="LandMark"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.landmark}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("landmark", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              name="typeaddress"
              label="Address Type"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.typeaddress}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("typeaddress", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              name="pinCode"
              label="Pin code"
              className="col-md-4"
              labelCol={{ span: 24 }}
              // Uncomment the rules below if you want to add validation rules
              rules={[validateRules.required, validateRules.zip]}
            >
              <Input
                value={shippingAddress.pinCode}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("pinCode", e.target.value)
                }
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <Header />

      <section className="py-5 mt-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="section-heading">
                <h3 className="introducing theme-bg-text font-hp">Checkout</h3>
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-md-12 row mt-5">
              {renderShippingAddressForm()}
              {renderCardtotals()}
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        visible={orderType}
        onCancel={() => {
          setpaynow(false);
        }}
        width="770px"
        height="300px"
        style={{ marginTop: "2%" }}
        footer={null}
      >
        <Result
          status="success"
          title="Order Confirmed"
          subTitle={`Your order has been successfully confirmed. Order number: ${OrderId}. Preparing your delicious meal, server takes 10-15 minutes, please wait patiently.`}
          extra={[
            <Button
              onClick={() => {
                SetOrderType(false);

                navigate(`/account`);
              }}
              type="primary"
              key="track"
            >
              Track Order
            </Button>,
            <Button
              key="newOrder"
              onClick={() => {
                SetOrderType(false);
                navigate(`/menu`);
              }}
            >
              Place New Order
            </Button>,
          ]}
        />
      </Modal>
      <Modal
        visible={orderfail}
        onCancel={() => {
          SetOrderfail(false);
        }}
        width="770px"
        height="300px"
        style={{ marginTop: "2%" }}
        footer={null}
      >
        <Result
          status="error"
          title="Order Failed"
          subTitle="We're sorry, but there was an issue processing your order. Please review the following information and try again."
          extra={[
            <Button
              onClick={() => {
                SetOrderfail(false);
              }}
              type="primary"
              key="retry"
            >
              Retry Order
            </Button>,
            <Button
              onClick={() => {
                SetOrderfail(false);
              }}
              type
              key="contact"
            >
              Contact Support
            </Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Unfortunately, your order could not be processed due to the
                following reasons:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
              Your payment method was declined. Please check your payment
              details and try again.
            </Paragraph>
          </div>
        </Result>
      </Modal>
      <CardDrawerMap
          openDrawer={drawer}
          onClose={() => {
            setDrawer(!drawer);
          }}
          onPass={(val)=>{
            console.log(val,"onPass");
            form.setFieldsValue({
              address: val.fullAddress,
              pinCode: val.pincode,
              typeaddress: val.addressType,
              FlatNo: val.doorFlatNo,
              landmark: val.landmark,


              // Add other fields as needed
            })
            setLatitude(val.location.lat)
            setLongitude(val.location.lng)


          }}
        />
    </>
  );
};

export default Payment;
