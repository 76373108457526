import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Select, Button, message } from 'antd';
import constant from '../constant/constant';

import { XOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function Footer({ onTabclick }) {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      // Handle form submission here
      console.log("Received values:", values);

      const response = await fetch(
        `${constant.baseUrl}local-heros-submit-form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        message.success("Form submitted successfully");
        // Reset form fields after successful submission (if using Ant Design Form)
        form.resetFields();
      } else {
        console.error("Error sending email:", response.statusText);
        message.error("Error submitting form");
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      message.error("An unexpected error occurred");
    }
  };
  return (
    <footer className="pt-4">
      <div className="container text-white">
      <h2
            className="mb-3 mt-3 d-md-none d-block font-hp"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
           Contact Us
          </h2>
        <div className="row text-center align-items-center pt-4 border-white">
          <div className="col-md-4 md-mb-0 mb-4">
            <img src={require("../assets/images/logos.webp")} className="w-75" alt="Logo" />
          </div>
          <div className="col-md-4 text-center md-mb-0 mb-4">
            <p>
              Address: 3rd Floor, 9th, A Main 559,<br/> 1st Stage, Indiranagar, <br />Bengaluru, Karnataka 560008
            </p>
            <p>
              Phone: +91 98440 15563
            </p>
            <p>
              Email:  info@localheros.in
            </p>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61560987504726" target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="" target="_blank">
                <XOutlined className='text-white'/>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/103050865/admin/dashboard/" target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/local_heros_foods" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>

          </div>
          <div className="col-md-4">
            <h5 className='text-white d-md-block d-none'>Contact Us</h5>
            <Form
              id="footer-form"
              onFinish={onFinish}
            >
              <div className="row mb-2">
                <div className="col-md-6 col-sm-12 md-mb-0">
                  <Form.Item
                  className='m-2'
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name' }]}
                  >
                    <Input placeholder="Name" className="form-control" />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-sm-12">
                  <Form.Item
                   className='m-2'
                    name="email"
                    rules={[
                      { required: true, message: 'Please enter your email' },
                      { type: 'email', message: 'Please enter a valid email address' }
                    ]}
                  >
                    <Input placeholder="Email" className="form-control" />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-lg-2">
                <div className="col-3">
                  <select
                    name="ccode"
                    placeholder="contrycode"
                    className="form-control m-2"
                  >
                    <option value="+91">+91</option>
                  </select>
                </div>
                <div className="col-9">
                  <Form.Item
                  className='m-2'
                    name="phone"
                    rules={[{ required: true, message: 'Please enter your phone number' }]}
                  >
                    <Input placeholder="Phone" className="form-control" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <Form.Item
                   className='m-2'
                   name="message">
                    <Input.TextArea placeholder="Message" rows={3} className="form-control" />
                  </Form.Item>
                </div>
              </div>
              <button id="f_btn1" className="footer-button d-flex mx-auto my-3" htmlType="submit">
                Submit
              </button>
            </Form>
            <>
            </>
          </div>
        </div>

      </div>
      <div className='border-top border-white bg-orange  mt-3 py-3 '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5 ps-0'>
              <ul className="quick-links d-flex mb-0 ps-0 me-0 text-dark">
                <li><Link to="/" className='text-decoration-none text-dark  pr-3' onClick={() => {
                  onTabclick('myElement5', 'Home');
                }}>Home</Link></li>
                <li><Link to="/" className='text-decoration-none text-dark' onClick={() => {
                  onTabclick('myElement', 'About Us');
                }}>About Us</Link></li>
                <li><Link to="/menu" className='text-decoration-none text-dark '>Menu</Link></li>
                <li><Link to="/blog" className='text-decoration-none text-dark '>Blog</Link></li>
                {/* <li><Link to="/policy" className='text-decoration-none text-dark '>Privacy Policy</Link></li> */}
                <li><Link to="/terms" className='text-decoration-none text-dark '>T&C</Link></li>
                {/* <li><Link to="/refund" className='text-decoration-none text-dark '>Cancellation and Refund</Link></li>
                <li><Link to="/contact" className='text-decoration-none text-dark '>Shipping and Delivery</Link></li> */}


              </ul>
            </div>
            <div className='col-md-4 text-center'>
              <p className='mb-0 text-center copy-rights'>Copyright © 2024 Local Heros Pvt Ltd</p>
            </div>
            <div className='col-md-3 text-center '>
              <a href="https://www.imsolutions.co/" className='text-dark text-decoration-none'>
              <p className='mb-0 text-center copy-rights'>Digital Partner - IM Solutions</p>

              </a>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  )
}
