import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./reducer/store";
import { Provider } from "react-redux";
import Offline from "./componets/Offline";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const onlineCheck = navigator.onLine;

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
root.render(
  onlineCheck === true ? (
    <React.StrictMode>
     <Provider store={store}>
       <App />
     </Provider>
   </React.StrictMode>
  ) : (
    <Offline />
  ),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();