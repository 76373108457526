// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup,signInWithRedirect, GoogleAuthProvider, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD56ElITvDOtmt6yBSzWtOWwdKk1XJfPVo",
    authDomain: "localheros.firebaseapp.com",
    projectId: "localheros",
    storageBucket: "localheros.appspot.com",
    messagingSenderId: "917692287750",
    appId: "1:917692287750:web:fba929ec2a098a12006c87",
    measurementId: "G-9RP56RPBJR"
};

// Initialize Firebase
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
    prompt: "select_account "
});
export const auth = getAuth();
// Function to sign in with Google Popup
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


export const signInWithGooglePopup = () => isSafari ? signInWithRedirect(auth, provider) : signInWithPopup(auth, provider); // Function to sign in with Google Popup

// Function to send OTP via SMS
