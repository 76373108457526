import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import {
  fetchBannerData,
  fetchProductData,
  PincodeModalOpen,
  AddCardProductById,
  loginModalOpen,
} from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constant from "../constant/constant";
import { TypeAnimation } from "react-type-animation";
import {
  message,
  Modal,
  Dropdown,
  Button,
  Popconfirm,
  Segmented,
  Select,
  InputNumber,
} from "antd";
import SplashScreen from "../componets/SplashScreen";
import Banners from "../componets/Banners";
import {
  UserOutlined,
  ShoppingCartOutlined,
  DownOutlined,
} from "@ant-design/icons";
import CardDrawer from "./CardDrawer";
import Carousel from "react-bootstrap/Carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import Bannersmenu from "../componets/Bannersmenu";
const popmod = {
  marginTop: "3%",
  backgroundColor: "red",
};
const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const { Option } = Select;

export default function Menu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [options, setOptions] = useState(false);
  const [optionslist, setOptionsArray] = useState([]);
  const [productid, setProductName] = useState("");
  const [menuselect, setMenuselect] = useState("");
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [value, setValue] = useState("All");
  const [select, setSelect] = useState(0);
  const [isenable, setenable] = useState(false);

  // A state to track the expanded/collapsed view for descriptions
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const myElementRef = useRef(null); // Define a ref using useRef hook

  const [selectindex, setSelectIndex] = useState(0);
  const [selectindex2, setSelectIndex2] = useState(false);

  let pincodeId = localStorage.getItem("pincode");
  let userId = localStorage.getItem("userId");

  // Destructuring with different names to avoid conflict
  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 1000,
      // once: true
    });
    AOS.refresh();
  }, []);

  const {
    productlist,
    loading: productListLoading,
    error: productListError,
  } = useSelector((state) => state.productlist);
  console.log(
    productlist && productlist.userProducts && productlist.userProducts,
    "product"
  );

  document.title = "Local Heros";
  document.getElementsByTagName("META")[2].content = "Local Heros";

  const handleNavigation = (productId) => {
    console.log(pincodeId);
    if (pincodeId !== null) {
      navigate(`/product/${productId}`);
    } else {
      dispatch(PincodeModalOpen(true));
    }
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const handleType = (key) => {
    switch (key) {
      case "Veg":
        return;
      case "Non Veg":
        return;
      case "Veg":
        return;

      default:
        break;
    }
  };

  const HandleDrawer = async (id, optionsID) => {
    if (userId) {
      let addcarditem = {
        userId: userId,
        productId: id,
        quantity: "1",
        Options_item: "",
        Combo_type: false,
      };
      await dispatch(AddCardProductById(addcarditem));
      if (optionsID) {
        let addcarditems = {
          userId: userId,
          productId: optionsID,
          quantity: "1",
          Options_item: "",
          Combo_type: false,
        };
        await dispatch(AddCardProductById(addcarditems));
      }
      setDrawer(!drawer);
    } else {
      message.info(`Pls select the login`, 5);
      await dispatch(loginModalOpen(true));
    }
  };
  useEffect(() => {
    dispatch(fetchBannerData());
    dispatch(fetchProductData());
  }, []);

  useEffect(() => {
    if (
      productlist?.userProducts &&
      data?.Categorys &&
      data?.Categorys.length > 0
    ) {
      changeMenu(data.Categorys[0]._id, 0); // Assuming you want to pass the ID of the first category
      const myElement = document.getElementById("myElement2");
      if (myElement) {
        // Do something with the element, for example, scroll to it
        myElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data?.Categorys]); // Only trigger when data.Categorys changes
  const changeMenu = (id, index) => {
    setSelectIndex(index);
    setMenuselect(id);
    setSelectIndex2(false);
    // Filter the user products based on the provided id
    const filteredProducts = productlist.userProducts.filter((product) => {
      return product.category === id;
    });
    // const filteredProducts = productlist.userProducts
    const filteredProductOptions = productlist.userProducts.filter(
      (product) => {
        return product.category === "661133469875ecd47c09fe74";
      }
    );

    console.log("Filtered Products:", JSON.stringify(filteredProducts, 0, 2));

    // Update the state with the filtered products
    setProduct([...filteredProducts]); // Using spread operator to create a new array
    if (index === 0) {
      setOptionsArray([...filteredProductOptions]); // Using spread operator to create a new array
    }

    console.log("Updated Product State:", product);

    // You can also directly return the filtered products if needed
    return filteredProducts;
  };

  const changeMenuitem = (id, index) => {
    setSelectIndex2(true);
  };

  const changeTabMenu = (type) => {
    let filteredProducts;

    if (type === "All") {
      filteredProducts = productlist.userProducts.filter((product) => {
        return product.category === menuselect;
      });
    } else {
      filteredProducts = productlist.userProducts.filter((product) => {
        return product.is_type === type && product.category === menuselect;
      });
    }

    setProduct([...filteredProducts]);
  };

  const items = [
    {
      key: "1",
      label: "Biryani By Box",
      onClick: () => setMenuselect("0"),
    },
    {
      key: "2",
      label: "Biryani By Box",
      onClick: () => setMenuselect("0"),
    },
    {
      key: "3",
      label: "Family Biryani",
      onClick: () => setMenuselect("1"),
    },
    {
      key: "4",
      label: "Party Biryani",
      onClick: () => setMenuselect("2"),
    },
  ];

  const getProductPath = (productId) => {
    if (pincodeId && userId) {
      return `/product/${productId}`;
    } else {
      if (pincodeId) {
        message.info(`Pls select the login`, 5);
      } else if (userId) {
        message.info(`login in once can you select the product`, 5);
      }
    }
  };
  const renderDynamicValues = (dataFromBackend) => {
    // Split the string by both commas and '\n' to get an array of lines
    const lines = dataFromBackend.split(/[+\n]/);

    // Filter out any empty or whitespace-only lines
    const filteredLines = lines.filter((line) => line.trim() !== "");

    // Map each line to a <li> tag wrapped in a <ul>
    const listItems = filteredLines.map((line, index) => (
      <li key={index}>{line.trim()}</li>
    ));

    // Return the list items wrapped in a <ul>
    return (
      <ul className="text-black text-start fs-6 list-style">{listItems}</ul>
    );
  };

  const renderDynamicValue = (dataFromBackend) => {
    // Split the string by both commas and '\n' to get an array of lines
    const lines = dataFromBackend.split(/[.\n]/);

    // Filter out any empty or whitespace-only lines
    const filteredLines = lines.filter((line) => line.trim() !== "");

    // Map each line to a <li> tag wrapped in a <ul>
    const listItems = filteredLines.map((line, index) => (
      <li key={index}>{line.trim()}</li>
    ));

    // Return the list items wrapped in a <ul>
    return (
      <ul className="text-black text-start fs-6 list-style">{listItems}</ul>
    );
  };

  const handleOpenChange = (visible) => {
    console.log("open change");
    setPopconfirmVisible(visible);
  };

  const handleClick = (id, menuItem) => {
    // Check if the menu item matches the desired value
    // Accessing the element by its ID and performing actions
    const myElement = document.getElementById("myElement2");
    if (myElement) {
      // Do something with the element, for example, scroll to it
      myElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDescriptionToggle = (id) => {
    // Toggle the state of the expanded description for the given id
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onHandleOptions = (item) => {
    if (item.options) {
      setOptions(true);
      setPopconfirmVisible(true);
      setProductName(item._id);
    } else {
      HandleDrawer(item._id, "");
      setProductName("");
    }
  };

  const selectItem = (item, prod_id, index) => {
    // HandleDrawer(item, prod_id);
    // setOptions(false);
    setSelect(index);
    setenable(false);
  };

  const confirm = () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(null), 3000);
      setPopconfirmVisible(false); // Optionally close the Popconfirm after confirmation
    });

  if (!data) {
    return <SplashScreen />;
  }
  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <div className="zindex-card">
        <CardDrawer
          openDrawer={drawer}
          onClose={() => {
            setDrawer(!drawer);
          }}
        />
        <Header onTabclick={handleClick} />
      </div>

      {/* <Bannersmenu /> */}

      <section className="py-5" id="" ref={myElementRef}>
        <div className="container mt-70">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              {/* <h2 className="theme-color menu-text font-hp">Menu</h2> */}
            </div>
            <p class="text-center anim p-3 mb-4">
              <span class="zoom">
                "All the prices mentioned are inclusive of taxes, premium
                packaging and zero cost delivery"
              </span>
            </p>
          </div>

          {/* <div>
            <ul
              className="nav nav-pills mb-3 categoryCard_wrapper col-md-12"
              id="pills-tab"
              role="tablist "
            >
             {data &&
  data.Categorys &&
  data.Categorys.filter((_, index) => index !== 1 && index !== 2).map((item, index) => (
    <li key={index} className="nav-item" role="presentation">
      <button
        className={`nav-link p-0 categoryCard col-md-3 ${index === 0 ? "active" : ""}`}
        id={`pills-${item._id.toLowerCase()}-tab`}
        data-bs-toggle="pill"
        data-bs-target={`#pills-${item._id.toLowerCase()}`}
        onClick={() => {
          changeMenu(item._id, index);
          const myElement = document.getElementById(`${item._id}`);
          if (myElement) {
            // Scroll to the element
            myElement.scrollIntoView({ behavior: "smooth" });
          }
        }}
        type="button"
        role="tab"
        aria-controls={`pills-${item._id.toLowerCase()}`}
        aria-selected={index === 0 ? "true" : "false"}
      >
        <img
          src={`${item.imageUrl}`}
          className="img-fluid rounded-circle image-card"
          width={"120px"}
          height={"45px"}
          alt="menu"
        />
        <p className="mb-0">{item.name}</p>
      </button>
    </li>
  ))}

            </ul>
          </div> */}
          <div className="col-md-12 text-end mb-4 d-flex justify-content-between">
            <h4 className="text-start  fw-semibold ">Categories</h4>
            {/* <Segmented
              options={[
                {
                  label: "All",
                  value: "All",
                  style: { backgroundColor: "lightblue", color: "darkblue" },
                },
                {
                  label: "Veg",
                  value: "Veg",
                  style: { backgroundColor: "lightgreen", color: "green" },
                },
                {
                  label: "Non Veg",
                  value: "Non Veg",
                  style: { backgroundColor: "lightcoral", color: "darkred" },
                },
              ]}
              style={{
                backgroundColor: "#e57228",
                padding: "5px",
                borderRadius: "5px",
              }}
              value={value}
              onChange={(item) => {
                console.log(item);
                changeTabMenu(item);
                setValue(item);
              }}
            /> */}
            <div>
              <button
                className="btn-vna fw-semibold"
                style={{
                  border:
                    value === 1 ? "0px solid #ff8900" : "2px solid #ff8900",
                  color: value === 1 ? "#fff" : "#ff8900", // Set white text color when active
                  fontSize: "smaller",
                  backgroundColor: value === 1 ? "#ff8900" : "transparent", // Background orange when active
                }}
                onClick={() => {
                  changeTabMenu("All");
                  setValue(1);
                }}
              >
                All
              </button>
              <button
                className="btn-vna"
                style={{
                  border: value === 2 ? "0px solid green" : "2px solid green",
                  color: value === 2 ? "#fff" : "green", // Set white text color when active
                  fontSize: "smaller",
                  backgroundColor: value === 2 ? "green" : "transparent", // Background green when active
                }}
                onClick={() => {
                  changeTabMenu("Veg");
                  setValue(2);
                }}
              >
                Veg
              </button>
              <button
                className="btn-vna"
                style={{
                  border: value === 3 ? "0px solid red" : "2px solid red",
                  color: value === 3 ? "#fff" : "red", // Set white text color when active
                  fontSize: "smaller",
                  backgroundColor: value === 3 ? "red" : "transparent", // Background red when active
                }}
                onClick={() => {
                  changeTabMenu("Non Veg");
                  setValue(3);
                }}
              >
                Non Veg
              </button>
            </div>
          </div>

          {
            <div className="d-md-block d-none">
              <ul
                className="nav nav-pills mb-3 categoryCard_wrapper col-md-12"
                id="pills-tab"
                role="tablist "
              >
                {data &&
                  data.Categorys &&
                  data.Categorys.map((item, index) => (
                    <li key={index} className=" " role="presentation">
                      <button
                        className={`nav-link  categoryCard mg-0 overflow-hidden${
                          index === 0 ? "" : ""
                        }`}
                        id={`pills-${item.name.toLowerCase()}-tab`}
                        data-bs-toggle="pill"
                        // data-bs-target={`#pills-${item.name.toLowerCase()}`}
                        onClick={() => {
                          changeMenu(item._id, index);
                          const myElement = document.getElementById("Menuid");
                          if (myElement) {
                            // Do something with the element, for example, scroll to it
                            myElement.scrollIntoView({ behavior: "smooth" });
                          }
                        }}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${item.name.toLowerCase()}`}
                        aria-selected={index === 0 ? "true" : "false"}
                      >
                        <img
                          src={`${item.imageUrl}`}
                          className="img-fluid image-card"
                          width={"210px"}
                          height={"200px"}
                          alt="menu"
                        />
                        <p className="mb-0 itm-na">
                          {" "}
                          <span className="d-block my-auto"> {item.name}</span>
                          {item.subname ? (
                            <span className="d-block my-auto mt-1">
                              {" "}
                              ({item.subname})
                            </span>
                          ) : null}
                        </p>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          }

          <div className="d-md-none d-block">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              // autoplay={{
              //   delay: 3000, // Delay in ms between auto scrolls
              //   disableOnInteraction: false, // Whether to pause autoplay when user interacts
              // }}
              modules={[Pagination, Autoplay]} // Add Autoplay to the modules array
              className="mySwiper"
            >
              {data &&
                data.Categorys &&
                data.Categorys.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="card-swip " role="presentation">
                      <button
                        className={`nav-link categoryCard ${
                          index === 0 ? "" : ""
                        }`}
                        id={`pills-${item.name.toLowerCase()}-tab`}
                        onClick={() => {
                          changeMenu(item._id, index);
                          const myElement = document.getElementById(`${item}`);
                          if (myElement) {
                            myElement.scrollIntoView({ behavior: "smooth" });
                          }
                        }}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${item.name.toLowerCase()}`}
                        aria-selected={index === 0 ? "true" : "false"}
                      >
                        <img
                          src={`${item.imageUrl}`}
                          className="img-fluid rounded-circle image-card"
                          width="200px"
                          height="200px"
                          alt="menu"
                        />
                        <p className="mb-0 itm-na">
                          <span className="d-block my-auto">{item.name}</span>
                        </p>
                      </button>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          {
            <div className="menu-box-bg d-md-block d-none" id="Menuid">
              <div className="row justify-content-center">
                <div className="col-md-12 mb-5 text-center">
                  <div class="tab-content">
                    <div className="row d-flex justify-content-center">
                      {data &&
                        product &&
                        product.map((item) => (
                          <React.Fragment>
                            <div className="col-md-4 mb-3 ">
                              <div className="menu-box rounded ">
                                {item?.images.length > 1 ? (
                                  <Carousel
                                    afterChange={onChange}
                                    interval={3000}
                                    indicators={true}
                                    controls={false}
                                    wrap={true}
                                  >
                                    {item.images.map((image, index) => (
                                      <Carousel.Item key={index}>
                                        <img
                                          src={image}
                                          className="img-fluid menu-box-img rounded w-100"
                                          alt={`menu ${index}`}
                                          onClick={() =>
                                            handleNavigation(item._id)
                                          }
                                        />
                                      </Carousel.Item>
                                    ))}
                                  </Carousel>
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    className="img-fluid rounded w-100 menu-box-img "
                                    alt="menu"
                                    onClick={() => handleNavigation(item._id)}
                                  />
                                )}

                                <div className="d-flex justify-content-between">
                                  <h3 className="text-black text-start px-2">
                                    {item.name.includes("(")
                                      ? item.name.split("(")[0]
                                      : item.name}
                                  </h3>
                                  <div>
                                    {/* <p className="price-button text-black fs-4 ">
                                  ₹{item.amount}{" "}
                                </p> */}
                                    {/* <del className=" text-danger stickout-price fs-6">₹ {item.offeramount}</del>
                                  <p className="price-button text-black fs-4 ">
                                    ₹{item.amount}{" "}

                                  </p> */}
                                  </div>
                                  <div>
                                    {item.is_type === "Non Veg" ? (
                                      <img
                                        src={require("../constant/images/non-veg.jpg")}
                                        alt="veg"
                                        className="veg_nonveg_icon"
                                      />
                                    ) : (
                                      <img
                                        src={require("../constant/images/veg-image.jpg")}
                                        alt="nonveg"
                                        className="veg_nonveg_icon"
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="row d-flex align-items-center">
                                  {/* <div className="col-lg-9 col-9">
                                    {item.subname !== "" && (
                                      <p className="text-black text-start px-2">
                                        {expandedDescriptions[item._id]
                                          ? item.subname
                                          : item.subname}
                                      </p>
                                    )}
                                  </div> */}
                                  <div className="col-lg-12 text-end mb-0">
                                    <h3 className="price-button text-black fs-4 d-flex w-100 ">
                                      ₹{item.amount}
                                      {"\t"}
                                      <span
                                        className="text-black text-start fw-14 
                                      "
                                      >
                                        (Zero cost delivery, inclusive of taxes and packaging)
                                      </span>
                                      {/* <span className=" text-danger stickout-price ms-3 strik-price">
                                    ₹ {item.offeramount}
                                  </span> */}
                                    </h3>
                                  </div>
                                </div>

                                {/* Show description with a "view more" option */}

                                {item.description && (
                                  <div className="text-black fs-6 text-start px-2 d-none">
                                    {expandedDescriptions[item._id] ? (
                                      <span>
                                        {" "}
                                        {selectindex === 2 || selectindex === 3
                                          ? renderDynamicValues(
                                              item.description
                                            )
                                          : item.description}
                                      </span>
                                    ) : (
                                      <span>
                                        {" "}
                                        {item.description.slice(0, 70)}{" "}
                                      </span>
                                    )}
                                    {item.description.length > 75 &&
                                      !expandedDescriptions[item._id] &&
                                      "..."}
                                    <div className="d-flex justify-content-center align-items-center">
                                      <p
                                        className="text-black border border-dark rounded mt-1 p-1 w-auto"
                                        onClick={() =>
                                          handleDescriptionToggle(item._id)
                                        }
                                      >
                                        {expandedDescriptions[item._id]
                                          ? "Read Less"
                                          : "Read More"}
                                      </p>
                                    </div>
                                  </div>
                                )}

                                <p className="text-dark line-space-with">
                                  {/* {item.name.includes('(') ? '(' + item.name.split('(')[1] : ''} */}

                                  <span className="ms-2 ">
                                    {item.description !== "" &&
                                    expandedDescriptions[item._id]
                                      ? item.description
                                      : item.description.slice(0, 40) + "..."}
                                  </span>
                                </p>

                                {/* <div className=" text-danger stickout-price fs-6">₹ {item.offeramount}</div>
                              <p className="price-button text-black fs-4 ">
                                ₹{item.amount}{" "}

                              </p> */}

                                <div
                                  className="d-flex justify-content-around align-items-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* <p onClick={() => handleNavigation(item._id)} className="price-button text-black">

                                {" "}
                                ₹{item.amount}{" "}
                              </p> */}

                                  <div className="d-flex justify-content-center align-items-center ">
                                    <button
                                      className="text-black border border-dark w-auto px-4 add-card-button bg-white"
                                      onClick={() =>
                                        handleDescriptionToggle(item._id)
                                      }
                                    >
                                      {expandedDescriptions[item._id]
                                        ? "Read Less"
                                        : "Read More"}
                                    </button>
                                  </div>

                                  <button
                                    onClick={() => onHandleOptions(item)}
                                    className="add-card-button"
                                  >
                                    Add +
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* {selectindex === 1 || selectindex === 2 ? <div className="col-md-7 mb-3 " onClick={() => handleNavigation(item._id)}>
                            <div className="menu-box d-flex align-items-s card bg-theme-color p-4">

                              {item.subname !== "" && <p className="text-black">{item.subname}</p>}

                              <p className="text-black">{item.description}</p>
                            </div>

                          </div> : null} */}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* {selectindex !== 0 ? <div className="menu-content">

              <h3 className="theme-color">
                <span>90 Minutes - made to order from scratch</span>
              </h3>
              <h3 className="text-black">
                “Don't eat stale, when you can eat fresh”
              </h3>
            </div> : null} */}
            </div>
          }
          {
            <div className="menu-box-bg d-md-none d-block">
              <div className="row justify-content-center">
                <div className="col-md-12 mb-5 text-center">
                  <div class="tab-content">
                    <div className="row d-flex justify-content-center">
                      {data &&
                        product &&
                        product.map((item) => (
                          <React.Fragment>
                            <div className="col-md-4 mb-3 ">
                              <div className="row d-flex align-items-center">
                                <div className="col-7">
                                  <div className="">
                                    <div className="text-start px-2">
                                      {item.is_type === "Non Veg" ? (
                                        <img
                                          src={require("../constant/images/non-veg.jpg")}
                                          alt="veg"
                                          className="veg_nonveg_icon"
                                        />
                                      ) : (
                                        <img
                                          src={require("../constant/images/veg-image.jpg")}
                                          alt="nonveg"
                                          className="veg_nonveg_icon"
                                        />
                                      )}
                                    </div>
                                    <h6 className="font-sw text-black text-start px-2">
                                      {item.name.includes("(")
                                        ? item.name.split("(")[0]
                                        : item.name}
                                    </h6>
                                    <h6 className="font-sw price-button text-black d-flex w-100">
                                      ₹{item.amount}{" "}
                                      {/* <span className=" text-danger stickout-price ms-3 strik-price">
                                    ₹ {item.offeramount}
                                  </span> */}
                                    </h6>
                                    <div>
                                      {/* <p className="price-button text-black fs-4 ">
                                  ₹{item.amount}{" "}
                                </p> */}
                                      {/* <del className=" text-danger stickout-price fs-6">₹ {item.offeramount}</del>
                                  <p className="price-button text-black fs-4 ">
                                    ₹{item.amount}{" "}

                                  </p> */}
                                    </div>
                                  </div>

                                  {item.subname !== "" && (
                                    <p className="text-black text-start px-2 font-saz">
                                      (Inclusive of taxes, premium packaging and
                                      ZCD)
                                    </p>
                                  )}

                                  {/* Show description with a "view more" option */}

                                  {item.description && (
                                    <div className="text-black fs-6 text-start px-2 d-none">
                                      {expandedDescriptions[item._id] ? (
                                        <span>
                                          {" "}
                                          {selectindex === 2 ||
                                          selectindex === 3
                                            ? renderDynamicValues(
                                                item.description
                                              )
                                            : item.description}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {item.description.slice(0, 70)}{" "}
                                        </span>
                                      )}
                                      {item.description.length > 75 &&
                                        !expandedDescriptions[item._id] &&
                                        "..."}
                                      <div className="d-flex justify-content-center align-items-center">
                                        <p
                                          className="text-black border border-dark rounded mt-1 p-1 w-auto"
                                          onClick={() =>
                                            handleDescriptionToggle(item._id)
                                          }
                                        >
                                          {expandedDescriptions[item._id]
                                            ? "Read Less"
                                            : "Read More"}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <p className="text-dark line-space-with d-none">
                                    <span className="ms-2 ">
                                      {item.description !== "" &&
                                      expandedDescriptions[item._id]
                                        ? item.description
                                        : item.description.slice(0, 40) + "..."}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-5 pos-rel">
                                  {" "}
                                  {item?.images.length > 1 ? (
                                    <Carousel
                                      afterChange={onChange}
                                      interval={3000}
                                      indicators={true}
                                      controls={false}
                                      wrap={true}
                                    >
                                      {item.images.map((image, index) => (
                                        <Carousel.Item key={index}>
                                          <img
                                            src={image}
                                            className="img-fluid menu-box-img rounded w-100"
                                            alt={`menu ${index}`}
                                            onClick={() =>
                                              handleNavigation(item._id)
                                            }
                                          />
                                        </Carousel.Item>
                                      ))}
                                    </Carousel>
                                  ) : (
                                    <img
                                      src={item.images[0]}
                                      className="rounded w-100 menu-box-img1 "
                                      alt="menu"
                                      onClick={() => handleNavigation(item._id)}
                                    />
                                  )}
                                  <button
                                    onClick={() => onHandleOptions(item)}
                                    className="pos-ab"
                                  >
                                    Add +
                                  </button>
                                </div>
                              </div>

                              {/* <div className=" text-danger stickout-price fs-6">₹ {item.offeramount}</div>
                              <p className="price-button text-black fs-4 ">
                                ₹{item.amount}{" "}

                              </p> */}

                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ cursor: "pointer" }}
                              >
                                {/* <p onClick={() => handleNavigation(item._id)} className="price-button text-black">

                                {" "}
                                ₹{item.amount}{" "}
                              </p> */}

                                <div className="d-flex justify-content-center align-items-center ">
                                  <button
                                    className="text-black border border-dark w-auto px-4 add-card-button bg-white d-none"
                                    onClick={() =>
                                      handleDescriptionToggle(item._id)
                                    }
                                  >
                                    {expandedDescriptions[item._id]
                                      ? "Read Less"
                                      : "Read More"}
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* {selectindex === 1 || selectindex === 2 ? <div className="col-md-7 mb-3 " onClick={() => handleNavigation(item._id)}>
                            <div className="menu-box d-flex align-items-s card bg-theme-color p-4">

                              {item.subname !== "" && <p className="text-black">{item.subname}</p>}

                              <p className="text-black">{item.description}</p>
                            </div>

                          </div> : null} */}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          }
        </div>
      </section>
      <Footer />
      <Modal
        visible={options}
        onCancel={() => {
          setOptions(false);
        }}
        height="300px"
        width="1000px"
        style={popmod}
        className="reghu"
        footer={null}
      >
        <h4 className="text-center text-black font-weight-bold head-control mt-4 mb-5">
          Make The Box Of Joy To Box Of Celebration By Adding Juicy Kebabs
        </h4>

        <div className="row">
          {data &&
            optionslist &&
            optionslist.map((item, index) => (
              <div className="col-md-3 col-6 mb-4" key={item._id}   onClick={() => selectItem(productid, item._id, index)}>
                <div className={`keb-card ${
                        select === index ? "image-hovers" : "image-hover"
                      }`}>
                  <div className="food-rel">
                    <img
                      src={item.images[0]}
                      className={`w-100 rounded`}
                      alt="menu"
                    
                    />

                    {/* <div className="food-abs">
                  <p className="text-to-animate m-3">
                  {item.description.slice(0, 150) + "..."}{" "}
  </p>
                 

                  </div> */}
                  </div>
                  <div className=" justify-content-center mt-3 line-space-with prod-nam-pri">
                    <p
                      className="text-black mb-md-2"
                      style={{ fontWeight: 700 }}
                    >
                      {item.name.includes("(")
                        ? item.name.split("(")[0]
                        : item.name}{" "}
                    </p>
                    <div className="prod-nam-pri2">
                      <p>{item.subname ? item.subname : item.subname}</p>
                      <p className="text-black prod-pric"> ₹{item.amount} </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* {select === 0 && (
            <section>
              <h5>Combo offer Kabab</h5>
              <div className="col-md-12 d-flex justify-content-between align-items-center mb-4">
                <img
                  src={`https://storage.googleapis.com/email-js-1a09b.appspot.com/localHeros/1713270623833-883913782`}
                  className="img-fluid image-card"
                  width={"120px"}
                  height={"120px"}
                  alt="menu"
                />

                {!isenable ? (
                  <Button
                    onClick={() => {
                      setenable(true);
                      message.loading("Add the Combo with 90 gm Kabab ( 75 )");
                    }}
                    danger
                  >
                    + Add
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setenable(false);
                      message.loading(
                        "Remove the Combo with 90 gm Kabab ( 75 )"
                      );
                    }}
                    danger
                  >
                    Remove
                  </Button>
                )}
              </div>
              <h6>Combo with 90 gm Kabab ( 75 )</h6>
            </section>
          )} */}

          <section>
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="mx-2"
                type="primary"
                onClick={() => {
                  HandleDrawer(productid, "");
                  setOptions(false);
                  setenable(false);
                }}
              >
                Skip
              </Button>
              <Button
                onClick={() => {
                  if (select === 0) {
                    HandleDrawer(optionslist[select]._id, productid);
                  } else {
                    HandleDrawer(optionslist[select]._id, productid);
                  }
                  setOptions(false);
                  setenable(false);
                }}
                danger
              >
                Proceed
              </Button>
            </div>
          </section>
        </div>
      </Modal>
    </>
  );
}
