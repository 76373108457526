import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";

// import required modules
import { Pagination } from 'swiper/modules';

export default function BlogSwi() {
    return (
        <>
            <Swiper
                 slidesPerView={1}
                 spaceBetween={30}
                 pagination={{
                   clickable: true,
                 }}
                 modules={[Pagination]}
                  
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/rice.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Aged Rice</p>
                            </div>
                            {/* style="width:300px;height:300px;" */}
                            <div className="flip-card-back">
                                <p>
                                    We use 18-month-old Jeera Samba rice from Bardhaman,
                                    West Bengal. It has a low Glycemic Index and does not
                                    cause a spike in blood sugar levels.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/pressed-oil.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Cold Pressed Oil </p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We are the first Biryani company to use cold-pressed
                                    groundnut oil, so you can indulge guilt-free without
                                    worrying about extra calories or clogged arteries.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/small-farm (1).jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Chicken From Small Farms</p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We source our chicken directly from small farms,
                                    ensuring no antibiotics or steroids are used.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/organic-vegetable.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Organic Vegetables</p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We are the first Biryani company to use only certified
                                    organic vegetables, including green leafy vegetables
                                    like methi, palak, coriander and mint, making your
                                    Biryani pesticide-free.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/secret-masala.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Secret Masalas</p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We use a secret blend of 14 freshly pounded spices to
                                    create our Biryani masala.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/purifired-water.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Purified Water</p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We use only purified water for cooking your Biryani(
                                    NOTE: A lot of water is used to make a batch of
                                    biryani)
                                </p>
                            </div>
                        </div>
                    </div>

                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/hotsupplychain.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Hot Supply Chain</p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    After cooking food, especially rice, for 2 hours, a type
                                    of bacteria called Bacillus Cereus can form. This
                                    bacteria is harmful to your digestive system. To prevent
                                    this bacterial growth, we maintain the quality of your
                                    Biryani from when it is cooked until it is served
                                    through a seamless hot supply chain management system at
                                    a temperature of 63 degrees.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/Hygienic Kitchen.jpeg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">Hygienic Kitchen </p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We use a state-of-the-art kitchen to ensure that bare
                                    hands do not touch your food throughout making and
                                    delivering your Biryani. Our hygiene is non-negotiable;
                                    all our Biryani Heroes(Chefs) are well trained and
                                    maintain personal hygiene, such as masks, head caps,
                                    bathing, and no smoking, alcohol, or tobacco.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img
                                    src={require("../assets/images/artificial-color.jpg")}
                                    className="rot-img1 d-block mx-auto broder-radio-img"
                                    alt=""
                                />
                                <p className="title-lite">No Tastemakers & colours </p>
                            </div>
                            <div className="flip-card-back">
                                <p>
                                    We refrain from using artificial colours, preservatives,
                                    and taste enhancers to enhance the flavour and appeal of
                                    our Biryani.
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
