import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductUserById, AddCardProductById ,loginModalOpen} from "../../src/reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import constant from "../constant/constant";
import { Nav, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { message, Rate } from "antd";
import cardimage from "../constant/images/Cart.svg";
import Copyimage from "../constant/images/Copy.svg";
import Facebookimage from "../constant/images/Facebook.svg";
import Pinterestimage from "../constant/images/Pinterest.svg";
import Twitterimage from "../constant/images/Twitter.svg";
import {
  HeartOutlined,
  FacebookFilled,
  FacebookOutlined,
  PinterestOutlined,
  ShareAltOutlined
} from "@ant-design/icons";
import Footer from "../componets/Footer";
import Header from "../componets/Header";
// import MultiCarousel from "../componets/MultiCarousel";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from 'react-bootstrap/Carousel';



const options = {
  // loop: true,
  // center: true,
  items: 2,
  margin: 0,
  autoplay: false,
  dots: false,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

const Product = () => {
  let userId = localStorage.getItem("userId");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    ProductIdloading: getOrderUserLoading,
    ProductIderror: ProductIdError,
    GetProductId: GetProductIdResponse,
  } = useSelector((state) => state.GetProductId);

  const {
    productlist,
    loading: productListLoading,
    error: productListError,
  } = useSelector((state) => state.productlist);

  const { GetAddcardRes, addcardIdloading: addcardIdloading } = useSelector(
    (state) => state.GetAddcardRes
  );
  const [productId, setProductId] = useState(id);

  useEffect(() => {
    // Fetch product details based on the productId
    fetchProductbyId(productId);

    if (GetAddcardRes) {
      message.success("Success", 5); // Display success message for 5 seconds
      console.log(GetAddcardRes);
    }
  }, [dispatch, productId]);


  useEffect(() => {
    const handleSticky = () => {
      const stickyElements = document.querySelectorAll(".sticky-logo-1");

      stickyElements.forEach((element) => {
        const offset = element.offsetTop;

        if (window.pageYOffset > offset) {
          element.classList.add("sticky");
        } else {
          element.classList.remove("sticky");
        }
      });
    };

    window.addEventListener("scroll", handleSticky);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  const fetchProductbyId = (productI) => {
    dispatch(ProductUserById(productI));
  };

  const handleCopyUrl = () => {
    const currentUrl = window.location.href; // Get the current URL
    
    if (!navigator.clipboard) {
      // Clipboard API not supported
      console.error('Clipboard API not supported.');
      // You can provide a fallback mechanism or message to the user here
      return;
    }
  
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        console.log('URL copied successfully:', currentUrl);
        message.success('URL copied to clipboard');
        // You can show a success message or perform other actions here
      })
      .catch((error) => {
        console.error('Error copying URL:', error);
        message.error('Failed to copy URL');
        // Handle the error, such as showing an error message to the user
      });
  };

  const handleNavigation = (newProductId) => {
    // Update the productId state when navigation occurs
    setProductId(newProductId);
    // Navigate to the specified product id
    // navigate(`/product/${newProductId}`);
  };

  const renderProductImages = () => {
    const product = GetProductIdResponse?.Products || {};
  
    return (
      <Carousel interval={3000} indicators={true} wrap={true}>
        {product.images &&
          product.images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                src={`${image}`}
                alt={`Product Image ${index}`}
                className="d-block w-100" /* Use Bootstrap classes for responsiveness */
              />
            </Carousel.Item>
          ))}
      </Carousel>
    );
  };
  

  const addcard = (id) => {
    let addcarditem = {
      userId: userId,
      productId: id,
      quantity: "1",
    };
    dispatch(AddCardProductById(addcarditem));
  };

  const buyproduct = async (id) => {
    if(userId){
      let addcarditem = {
        userId: userId,
        productId: id,
        quantity: "1",
      };
      await dispatch(AddCardProductById(addcarditem));
      navigate("/cart");
    }else{
      message.info(`Pls select the login`, 5);
      await dispatch(loginModalOpen(true)) 
    }
    
  };

  const renderProductDetails = () => {
    const product = GetProductIdResponse?.Products || {};

    return (
      <div className="row mt-5 carousel-product price-card" key={product.id}>
        <div className="col-md-6">{renderProductImages()}</div>
        <div className="col-md-6">
          {/* <div className="d-flex align-items-start">
            {" "}
            <Rate disabled defaultValue={4} />
            <p className="px-1">4.7 Star Rating</p>{" "}
            <p className="px-1">(0 User feedback)</p>{" "}
          </div> */}
          <h3>{product.name}</h3>
          <p className="amount-label border-bottom pb-3">{product.description} {product.subname}</p>

          <div className="d-flex justify-content-between">
            <p className="w-50 d-flex">
              AVAILABILITY:{" "}
              <p className="text-success">{product.availability}</p>
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center row">
            <p className="amount-label  price-amount-big">₹ {product.amount}  <del className="amount-label stickout-price">₹ {product.offeramount}</del></p>

          </div>


          <div className="text-start">
            <p className="d-flex justify-content-start align-items-center">
              {/* <div className="quantity-col text-center  border mx-3 rounded">
                <button
                  className="quantity-btn mx-2"
                  // onClick={() =>
                  //   handleUpdateQuantity(
                  //     item._id,
                  //     item.quantity - 1
                  //   )
                  // }
                  // disabled={item.quantity <= 1}
                >
                  -
                </button>
                <span className="quantity fw-light">1</span>
                <button
                  className="quantity-btn mx-2"
                  // onClick={() =>
                  //   handleUpdateQuantity(
                  //     item._id,
                  //     item.quantity + 1
                  //   )
                  // }
                >
                  +
                </button>
              </div> */}
              <button
                className="button fw-light"
                onClick={() => buyproduct(product._id)}
              >
                Buy Now
              </button>

              <button
                className="btn button mx-3 bg-transparent border rounded-pill text-dark broder-color"
                onClick={() => addcard(product._id)}
              >
                <img src={cardimage} alt={`Product Image`} /> Add to Cart
              </button>
            </p>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <p className="w-50 d-flex">
              Share product:{<div className="mx-1" alt={`Product Image`} style={{ cursor:"pointer"}} onClick={handleCopyUrl}> 
              <ShareAltOutlined/>
               </div>}
              {/* {<img src={Facebookimage} className="mx-1" alt={`Product Image`} />}{" "} */}
              {/* {<img src={Twitterimage} className="mx-1" alt={`Product Image`} />}{" "}
              {<img src={Pinterestimage} className="mx-1" alt={`Product Image`} />} */}
            </p>
          </div>
        </div>
        {/* <h3 className="related-font">Related Biryani </h3>
        {renderRelatedProducts()} */}
        <div className="col-md-12 text-center mt-5 card-body p-5">
          {/* Product Tabs */}
          <Tab.Container defaultActiveKey="description">
            <Nav variant="tabs" className="justify-content-center ">
              <Nav.Item className="navigation-tabs-relat-byr">
                <Nav.Link eventKey="description" className="w-100 rounded-0 nv-lnk">Description</Nav.Link>
              </Nav.Item>
              <Nav.Item className="navigation-tabs-relat-byr">
                <Nav.Link eventKey="reviews" className="w-100 rounded-0 nv-lnk">Additional information</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="description" className="p-5 bg-white d-flex justify-content-center">
                {/* Description Tab Content */}
                <p className="w-75">{product.description}{product.subname}</p>
              </Tab.Pane>
              <Tab.Pane eventKey="reviews" className="p-5 bg-white d-flex justify-content-center">
                {/* Reviews Tab Content */}
                <div>
                  <p>dimensions:{product.dimensions}</p><br />
                  <p>Weight:{product.weight}</p>
                </div>

              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className="col-md-12 text-start mt-4">
          <div className="text-start">
            <p className="mt-3">Reviews</p>
            <p>There are no reviews yet.</p>
            <p>
              Only logged in customers who have purchased this product may leave
              a review.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderRelatedProducts = () => {
    return (
      <OwlCarousel
        className="owl-theme"
        margin={10}
        autoplay
        dots={false}
        responsive={{
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 5,
          },
        }}
      >
        {productlist?.userProducts?.slice(0, 8).map((prod, ind) => (
          <div className="portfolio price-card">
            <div key={ind} className="item" onClick={() => fetchProductbyId(prod._id)}
            >
              <img
                src={
                  prod.images[0] !== null && prod.images[0] !== "image_url1"
                    ? `${prod.images[0]}`
                    : "assets/images/Rectangle 22.png"
                }
                alt={`Web Project ${ind + 1}`}
              />
              <div className="product-title">
                <p className="text-black">
                  {prod.name} <br /> ₹{prod.amount}
                </p>
              </div>

              <div className="text-center">
                <button
                  className="btn button"
                  onClick={() => addcard(prod._id)}
                >
                  Add to Cart
                </button>

              </div>

            </div>
          </div>
        ))}
      </OwlCarousel>
    );
  };

  const renderImageProducts = () => {
    const product = GetProductIdResponse?.Products || {};

    return (
      <OwlCarousel className="owl-theme" loop margin={10} items={1}>
        {product.images &&
          product.images.map((image, index) => (
            <div key={index} className="item">
              <img
                src={`${image}`}
                alt={`Product Image ${index}`}
              />
            </div>
          ))}
      </OwlCarousel>
    );
  };

  return (
    <>
      <Header/>
      <section className="py-5 mt-80 mt-5">
        <div className="container">
          {renderProductDetails()}


        </div>
      </section>
      <Footer />
    </>
  );
};

export default Product;
