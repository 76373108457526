export default{
    // baseUrl:"http://192.168.88.84:5000/",
    // baseUrl:"http://192.168.0.122:5000/",
    // baseUrl:"http://192.168.92.90:5000/",
    // baseUrl: "http://ec2-13-60-22-195.eu-north-1.compute.amazonaws.com:5000/",
    // baseUrl: "https://us-central1-email-js-1a09b.cloudfunctions.net/backend/",
    // baseUrl: "http://13.60.22.195/",
     baseUrl: "https://api.localheros.in/",
    //   baseUrl:"http://192.168.88.55:4000/",   



}
