import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { fetchBannerData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';



export default function Refund() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Destructuring with different names to avoid conflict
    const {
        data,
        loading: bannerLoading,
        error: bannerError,
    } = useSelector((state) => state.data);


    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta
                    name="description"
                    content="This is a description of your page."
                />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>
            <Header />

            {/* <section>
        <Carousel className="cor-item">
          <Carousel.Item >
           <img src={require("../constant/images/banner1.jpeg")} className="w-100" />
            <div className="carousel-caption carousel-content mt-80">
            <TypeAnimation
              sequence={[
                `The hunger pang Biryani`,
                2000,
                `The No nonsense Biryani`,
                2000,
                ` The Happy Wala Biryani`,
                2000,
                `The Biryani of Mysore`,

              ]}
              repeat={Infinity}
              wrapper="h3"
            />
          </div>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
             
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
              
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </section> */}
            <section className="mt-5 mt-lg-0">
                <Carousel className="cor-item">
                    <Carousel.Item >
                        {/* <img src={require("../constant/images/banner1.jpeg")} className="w-100" /> */}

                        <picture>
                            <source
                                media="(min-width: 768px)"
                                srcSet={require("../constant/images/banner1.jpeg")}
                            />
                            <source
                                media="(max-width: 767px)"
                                srcSet={require("../constant/images/mob-banner-1.jpg")}
                            />
                            <img
                                src={require("../constant/images/banner1.jpeg")}
                                alt="Flowers"
                                style={{ width: "100%" }}
                            />
                        </picture>

                        <div className="carousel-caption carousel-content mt-80">
                            <TypeAnimation
                                sequence={[
                                    `The Hunger Pang Biryani`,
                                    2000,
                                    `The No Nonsense Biryani`,
                                    2000,
                                    ` The Happy Wala Biryani`,
                                    2000,
                                    `The Biryani Of Mysore`,
                                    2000

                                ]}
                                repeat={Infinity}
                                wrapper="h3"
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        {/* <img src={require("../constant/images/box-image.png")} className="w-100" /> */}
                        <picture>
                            <source
                                media="(min-width: 768px)"
                                srcSet={require("../constant/images/box-image.png")}
                            />
                            <source
                                media="(max-width: 767px)"
                                srcSet={require("../constant/images/mbanner-box.png")}
                            />
                            <img
                                src={require("../constant/images/box-image.png")}
                                alt="Flowers"
                                style={{ width: "100%" }}
                            />
                        </picture>

                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>

            </section>

            <section className="breadcum-bg " style={{ maxHeight: "60px" }}></section>
            <section className="my-md-5 my-3  bg-light"></section>
            <section className="py-lg-5 ">
                <div className="container about-box cont-bg">
                  
                    <div className="row py-5">
                        <div className="col-md-12 pb-5 pb-lg-0">
                            <div className="col-md-12 mb-4">
                                <strong>REFUND AND CANCELLATION POLICY</strong> <br/>
                                <br/>
                                <p>Local Heroes Pvt. Ltd (“Local Heroes”) reserves the right to refuse or cancel any order. Local Heroes at its sole discretion may cancel any order(s):</p>
                                <ul>
                                    <li>If it suspects a fraudulent transaction(s), or</li>
                                    <li>If it suspects a customer has undertaken a transaction which is not in accordance with its Terms and Conditions, or</li>
                                    <li>For any reason outside the control of Local Heroes.</li>
                                </ul>

                                <p>Refund/cancellation policies applicable in the following conditions:</p>
                                <ol>
                                    <li>In case the buyer cancels the order online a day before the service is scheduled, the amount will be refunded.</li>
                                    <li>In case of failed transactions or double realization of account for the same order, the total deducted amount will be refunded.</li>
                                    <li>In case of cancelled order/failed transactions, the bank/card transaction charges of the buyer, if any, are likely to be forfeited.</li>
                                    <li>Local Heroes offers no guarantees whatsoever for the accuracy or timeliness of the refunds in the buyer’s card/account.</li>
                                    <li>In case of part cancellations, the amount refunded will correspond to the part cancellation.</li>
                                </ol>

                                <p>Document Review Date: 01 March 2024<br/>
                                    Version 2 of the Refund and Cancellation Policy</p>

                            </div>



                        </div>

                    </div>

                </div>
            </section>



            <Footer />
        </>
    );
}
