import React from "react";
import { Button, Container } from "react-bootstrap";
import constant from "../constant/constant";
import Carousel from 'react-bootstrap/Carousel';

import { TypeAnimation } from "react-type-animation";

class Bannersmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    // Bind methods
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleAnimationEnd() {
    // Move to the next slide when TypeAnimation completes
    this.setState((prevState) => {
      if (prevState.index === 3) {
        return { index: 0 };
      } else {
        return { index: prevState.index + 1 };
      }
    });
  }

  handleSelect(selectedIndex) {
    console.log(selectedIndex);
    // Update the index state based on the selected index
    if (selectedIndex === 3) {
      this.setState((prevState) => ({ index: prevState.index + 1 }));
    } else {
      this.setState({ index: selectedIndex });
    }
  }

  render() {
    return (
      <section className="mt-lg-0">
        <Carousel className="cor-item" interval={3000} indicators={false}>
          
        <Carousel.Item>
        
        <picture>
          <source
            media="(min-width: 768px)"
            srcSet={require("../constant/images/LightwalletDesktop.png")}
          />
          <source
            media="(max-width: 767px)"
            srcSet={require("../constant/images/LightwalletMobile.png")}
            />

          <img
            src={require("../constant/images/box-image1.png")}
            alt="Flowers"
            style={{ width: "100%" }}
            className="mt-60"
          />
          
        </picture>
        

       
      </Carousel.Item>
          <Carousel.Item>
        
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/LightBannerDesktop.png")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/LightBannerMobile.png")}
                />

              <img
                src={require("../constant/images/box-image1.png")}
                alt="Flowers"
                style={{ width: "100%" }}
                className="mt-60"
              />
              
            </picture>
            

           
          </Carousel.Item>
        </Carousel>
      </section>
    );
  }
}

export default Bannersmenu;
