import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, Row, Col, Form, Rate } from "antd";
import moment from "moment";
import { OrderUserList } from "../reducer/thunks";

const Order = () => {
  const userId = localStorage.getItem("userId");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useDispatch();

  // Destructure state
  const {
    loading: isLoading,
    Ordererror: orderError,
    getOrder: getOrderResponse,
  } = useSelector((state) => state.getOrder);

  useEffect(() => {
    dispatch(OrderUserList(userId));
  }, [dispatch, userId]);

  // Define columns for the Ant Design Table component
  const columns = [
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
      render: (text) => `#${text.slice(-7)}`, // Format order ID
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY"), // Format date

    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="rectangle" style={{
          cursor: "pointer"
        }} onClick={() => handleViewMore(record)}>
          <p className="text-center p-texts">View Details</p>
        </div>
      ),
    },
  ];

  const handleViewMore = (record) => {
    setSelectedOrder(record);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  function getTotal() {
    return (
      selectedOrder?.products &&
      selectedOrder?.products.reduce(
        (total, item) => total + item.amount,
        0
      )
    );
  }
  function getQty(id) {
    // Find the product in the array based on the product ID
    const productqty = selectedOrder?.quantity?.find(
        (item) => item.productId === id
    );
    
    // Check if the product was found
    if (productqty) {
        // Return the quantity of the found product
        return productqty.quantity;
    } else {
        // If the product is not found, return a default value (e.g., 0)
        // or handle the case as needed
        return 0;
    }
}

  const shippingamount = () => {
    const calculatedAmount = parseFloat(selectedOrder?.totalAmount).toFixed(0);
  
    if (450 > +calculatedAmount) {
      return `${calculatedAmount} +(DC) 0 =`+ " " + Number(+calculatedAmount + 0);
    } else {
      return "Delivery Fee";
    }
  };

  const modalContent = (
    <div className="p-5 modal-content-container">
      <p>
        <strong>Customer Name:</strong> {selectedOrder?.user?.firstname}
      </p>
      <p>
        <strong>Address:</strong> {selectedOrder?.address?.street}
      </p>
      <p>
        <strong>Phone Number:</strong> {selectedOrder?.address?.phone}
      </p>
      <p>
        <strong>PIN Code:</strong> {selectedOrder?.address?.pinCode}
      </p>
      <p>
        <strong>State:</strong> {`${selectedOrder?.address?.state} ${selectedOrder?.address?.city}`}
      </p>
  
      <p className="mb-0">&nbsp;</p>
      {selectedOrder?.products.map((product) => (
        <Row key={product._id} gutter={[16, 16]}>
          <Col xs={24} sm={6} md={4}>
            {product?.product?.images?.length > 0 && (
              <img
                src={`${product?.product.images[0]}`}
                alt={product.product.title}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                className="product-image"
              />
            )}
          </Col>
  
          <Col xs={24} sm={12} md={10}>
            <p>
              <strong>Product:</strong> {product?.product?.name}
            </p>
            <p>
              <strong>Delivery Status:</strong> {selectedOrder?.paymentStatus}
            </p>
            <p>
              <strong>Payment Mode:</strong> {selectedOrder?.delivery}
            </p>
          </Col>
  
          <Col xs={24} sm={12} md={10}>
            <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Quantity:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">{product && getQty(product.product?._id)}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Sub-total:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">₹{product?.product?.amount}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Shipping fee:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">{product && shippingamount()}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
      <Row className="total-amount-row">
        <Col xs={24}  md={12}>
          <p className="text-start">
            <strong>Total Order Amount: ₹{selectedOrder?.totalAmount}</strong>
          </p>
        </Col>
        <Col xs={24} md={12}>
          <p className="text-end py-3 fs-4 fw-bold">
            {/* ₹{selectedOrder?.totalAmount} */}
          </p>
        </Col>
      </Row>
    </div>
  );
  

  return (
    <>
      <div className="col-md-9 p-4">
        <div className="col-md-12 bg-white p-3 rounded">
          <div className="mx-4 mt-3">
            <p className="p-header">Order</p>
            <Table
              dataSource={getOrderResponse?.orders}
              columns={columns}
              loading={isLoading}
              pagination={false} // Disable pagination, adjust as per requirements
              scroll={{ x: '100%' }} // Set horizontal scroll

            />
          </div>
        </div>
      </div>
      <Modal
        title="Order Details"
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
        className="card-body rounded mt-5"
      >
        {modalContent}
      </Modal></>

  );
};

export default Order;
